import { Component, OnInit, AfterViewInit, ViewChild, OnChanges, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ApplicantService } from '../services/applicant.service';
import { QuestionService } from '../services/question.service';
import { TosterService } from '../services/toster.service';
import { AllocationService } from 'src/app/services/allocation.service';

@Component({
  selector: 'app-grand-total-report',
  templateUrl: './grand-total-report.component.html',
  styleUrls: ['./grand-total-report.component.css'],
})

export class GrandTotalReportComponent implements OnChanges, OnInit, AfterViewInit {
  @ViewChild('dataTable', { static: false }) table!: ElementRef;
  applicant_id: any;
  section: any = [];
  criteria: any;
  app_email: any;
  questions: any;
  subsection: any;
  answer: any;
  public model: any = {};
  modall: any = [];
  public modelscore: any = {};
  modallscore: any = [];
  udata: any;
  sec_done: any = [];
  private dataTable: any;

  section_no: any;
  allocated_id: any;
  assessor_data: any;
  subSections: any;
  assessment_data: any;
  all_scores: any = [];
  scoreOne: any;
  SubSecmodel: any = {};
  HighScrmodel: any = {};
  disableStatus: any = {};
  finalScore: any = [];
  tl: any;
  conper: any = [];
  highScore: any = 0;
  count: any = [];
  disableddata: any;
  version: any = '1';
  criteriaArray: any = [];
  arrSubSec: any = [];
  applicantData: any = [];
  private dataTableInitialized = false;
  counted_total: any = [];
  final_total: any = [];
  applicantS: any;
  sections_all: any;
  app_ids: any;
  highScoreArray: any = [];
  countArray: any;
  AllocationList: any;
  scores: any = {};
  constructor(
    public allocation: AllocationService,
    private router: Router,
    private quest: QuestionService,
    private applicant: ApplicantService,
    private _Activatedroute: ActivatedRoute,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toast: TosterService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.udata = localStorage.getItem('userdata');
    this.udata = JSON.parse(this.udata);
    let email = this.udata.email;
    this.allocation.getAllAllocation().subscribe((item: any) => {
      this.AllocationList = item.result;
      this.AllocationList.sort((a: any, b: any) => {
        const sectorA = a.applicantData?.[0]?.sector?.toLowerCase() || '';
        const sectorB = b.applicantData?.[0]?.sector?.toLowerCase() || '';
        if (sectorA < sectorB) {
          return -1;
        }
        if (sectorA > sectorB) {
          return 1;
        }
        const nameCityA = `${a.applicantData?.[0]?.organizationName || ''}, ${a.applicantData?.[0]?.applicantCity || ''}`.toLowerCase();
        const nameCityB = `${b.applicantData?.[0]?.organizationName || ''}, ${b.applicantData?.[0]?.applicantCity || ''}`.toLowerCase();
        if (nameCityA < nameCityB) {
          return -1;
        }
        if (nameCityA > nameCityB) {
          return 1;
        }
        return 0;
      });

      for (const item of this.AllocationList) {
        const criteria = item['applicantData'][0]?.['criteria'];
        if (!this.applicantData.hasOwnProperty(criteria)) {
          this.applicantData[criteria] = [];
        }
        this.applicantData[criteria][item['applicant_id']] = [];
        this.applicantData[criteria][item['applicant_id']] = item;
      }
    });

    this.quest.viewCritSecs().subscribe((data: any) => {

      this.sections_all = data['criteriaSections'];
      this.criteriaArray = Object.keys(data.criteriaSections);
    });

    this.spinner.hide();
  }

  ngAfterViewInit() {
    this.initializeDataTable();
    this.dataTableInitialized = true;
  }

  ngOnChanges() {
    this.updateDataTable();
  }

  private initializeDataTable() {
    this.dataTable = $(this.table?.nativeElement).DataTable({
      dom: 'Bfrtip',
    });
  }

  private updateDataTable() {
    const dataTable = $(this.table.nativeElement).DataTable();
    dataTable.clear().destroy();
    this.initializeDataTable();
  }
  getCellStyle(value: number): { [key: string]: string } {
    if (value < 80) {
      return {
        'background-color': '#FFF1F1',
        'border-bottom': '2px solid red',
        'color': 'red',
        'font-weigth': '600'
      };
    } else {
      return {
        'background-color': '#F9F8F8',
        'border-bottom': '1px solid #ddd',
        'color': 'rgb(84 84 84);',
        'font-weigth': '600'
      };
    }
  }
  criteriaChange(criteria: any) {
    this.criteria = criteria;
    this.section_no = null
    this.section = this.sections_all[this.criteria];
    this.app_ids = Object.keys(this.applicantData[this.criteria] != null ? this.applicantData[this.criteria] : []);
    this.subSections = [];
    this.quest.getScores({ criteria: this.criteria }).subscribe((data: any) => {
      this.scores = data
    })
  }

  getscore(sec: any) {
    this.spinner.show();
    this.app_ids.forEach((app_id: any) => {
      this.final_total[app_id] = 0;
      this.getQuestion(sec, app_id);
    });
  }

  getQuestion(sec: any, app_id: any) {
    this.conper[app_id] = 0;
    this.highScore = 0;
    this.count[app_id] = 0;
    this.section_no = sec;
    this.finalScore = [];
    this.quest
      .getSubSection({
        criteria: this.criteria,
        section_no: sec,
      })
      .subscribe((itemQ: any) => {
        this.subSections = itemQ;
        const sublength = this.subSections.length;
        const subs_all: any = [];
        itemQ.map((item: any) => {
          subs_all.push(item._id.sub_section_no);
        });
        this.quest
          .getConsensusScore({
            allocation_id: this.applicantData[this.criteria][app_id]['_id'],
            criteria: this.criteria,
            section_no: this.section_no,
          })
          .subscribe((itemvalue: any) => {
            if (itemvalue.length == 0) {
              this.subSections.map((ssItem: any) => {
                if (this.final_total[app_id] == undefined) {
                  this.final_total[app_id] = 0;
                }
                if (this.SubSecmodel[app_id] == undefined)
                  this.SubSecmodel[app_id] = [];
                this.SubSecmodel[app_id][ssItem._id.sub_section_no] = '0';
                this.SubSecmodel[app_id]['total'] = '0';
              });
            } else {
              this.SubSecmodel[app_id] = itemvalue.con_score[0].Conscore[0];
              let aggregate = 0;
              let count3 = 0;
              for (let key in this.SubSecmodel[app_id]) {
                let value = parseInt(this.SubSecmodel[app_id][key]);
                if (!isNaN(value) && subs_all.includes(key)) {
                  aggregate += value;
                  count3 = count3 + 1;
                }
              }

              let new_avg = aggregate / sublength;
              this.SubSecmodel[app_id]['total'] = parseFloat(new_avg.toFixed(1));

              this.final_total[app_id] = this.SubSecmodel[app_id]['total'];
              this.section.map((subby: any) => {
                if (subby != sec) {
                  this.quest
                    .getSubSection({
                      criteria: this.criteria,
                      section_no: subby,
                    })
                    .subscribe((itemQQ: any) => {
                      let subSectionss = itemQQ;
                      const sublength_new = subSectionss.length;
                      const subs_all_new: any = [];
                      itemQQ.map((item: any) => {
                        subs_all_new.push(item._id.sub_section_no);
                      });
                      this.quest
                        .getConsensusScore({
                          allocation_id: this.applicantData[this.criteria][app_id]['_id'],
                          criteria: this.criteria,
                          section_no: subby,
                        })
                        .subscribe((itemvalue: any) => {
                          if (itemvalue.length == 0) {
                          } else {
                            let noob = itemvalue.con_score[0].Conscore[0];
                            let aggregate = 0;
                            let count3 = 0;
                            for (let key in noob) {
                              let value = parseInt(noob[key]);
                              if (!isNaN(value) && subs_all_new.includes(key)) {
                                aggregate += value;
                                count3 = count3 + 1;
                              }
                            }
                            let new_avg = aggregate / sublength_new;
                            this.final_total[app_id] = this.final_total[app_id] + parseFloat(new_avg.toFixed(1));
                            this.final_total[app_id] = parseFloat(this.final_total[app_id].toFixed(1));
                          }
                        });
                    });
                }
              });
            }
            this.spinner.hide();
          });
      });
  }

  checkValue(secno: string, i: any): boolean {
    var test = false;
    var data = this.all_scores;
    data.forEach((item: any) => {
      if (item[0]?.applicable[0][secno]) {
        if (i == 0 || i == '0' || i == '' || i == 0.0) {
          test = true;
          this.updateValues(secno);
          this.sec_done.push(secno);
        }
      }
    });
    this.sec_done.push(secno);
    return test;
  }

  updateValues(ssno: any) {
    this.conper = 0;
    this.highScore = 0;
    this.count = 0;
    this.HighScrmodel[ssno] = 0;
    this.SubSecmodel[ssno] = 0;
    if (true) {
      this.arrSubSec.push(ssno)
    }
    else {
      var index = this.arrSubSec.findIndex(function (o: any) {
        return o === ssno;
      });
      if (index !== -1) this.arrSubSec.splice(index, 1);
    }
    this.finalScore.map((itemm: any) => {
      if (itemm.subsectionno === ssno) {
        itemm.score = 0;
        itemm.highestscore = 0;
        itemm.consensusscore = 0;
        itemm.chkStatus = true;
      }
    })
    for (var el in this.HighScrmodel) {
      if (this.HighScrmodel.hasOwnProperty(el)) {
        this.highScore += parseFloat(this.HighScrmodel[el]);
        this.count +=
          (parseFloat(
            this.SubSecmodel[el] == 'NA'
              ? 0
              : this.SubSecmodel[el] == ''
                ? 0
                : this.SubSecmodel[el]
          ) /
            100) *
          parseFloat(this.HighScrmodel[el]);
      }
    }
    //console.log(this.finalScore,'finalscore')
  }

  compareDates(dt: any): boolean | undefined {
    let currentDate = new Date();
    let applicantDate = new Date(dt);
    let threeYearsAgo = new Date(currentDate.getFullYear() - 3, currentDate.getMonth(), currentDate.getDate());
    if (applicantDate.getTime() >= threeYearsAgo.getTime() && applicantDate.getTime() <= currentDate.getTime()) {
      return true;
    }
    return false;
  }
}