import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-commonhead',
  templateUrl: './commonhead.component.html',
  styleUrls: ['./commonhead.component.css'],
})
export class CommonheadComponent implements OnInit {
  @Input() applicant_id: any;
  @Input() allocation_id: any;
  udata: any = {};
  constructor(
    public router: Router
  ) { }

  ngOnInit(): void {
    this.udata = JSON.parse(localStorage.getItem('userdata') || '{}')
  }
}
