<app-header></app-header>
<app-sidenav *ngIf="this.udata.role!='assessor'"></app-sidenav>
<app-sidenav-assessors *ngIf="this.udata.role=='assessor'"></app-sidenav-assessors>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Hold on while we take you to your CII FACE Account! </p>
</ngx-spinner>
<div id="content" class="app-content" role="main">
  <div class="app-content-body mar-in-20">
    <div>
      <h2>Sector Tally Report</h2>
    </div>
    <ul class="nav nav-tabs bb-1" *ngIf="criteriaArray.length > 1">
      <li class="nav-item" role="presentation" *ngFor="let crt of criteriaArray" [class.active]="criteria == crt"
        (click)="criteriaChange(crt)">
        <a>{{crt}}</a>
      </li>
    </ul>
    <div class="clearfix"></div>
    <div>
      <ul class="d-flex bb-0 list-style-none sub-checklist">
        <li class="nav-tabs-item" role="presentation" [class.active]="sector.includes(sct)"
          *ngFor="let sct of sectorArray | slice:0:5" (click)="sectorChange(sct)">
          <a style="font-size: 14px;">
            {{ sct }}
          </a>
        </li>
        <li class="nav-tabs-item" data-toggle="modal" data-target="#exampleModal" role="presentation"
          *ngIf="this.sectorArray.length>5"><a style="font-size: 14px;">See More</a>
        </li>
      </ul>
    </div>

    <p class="text-danger" style="margin-left:2rem ;margin-top: 1rem;"><i class="fa fa-info-circle"></i> Select
      checkbox if any sub section is Not Applicable in this assessment</p>
    <div class="lock-header">
      <table class="table table-bordered" *ngIf="section.length">
        <thead>
          <tr>
            <th class="headcol" style="position: sticky;
              top: 0;
              background-color: #D8E4BC;">Unit Name</th>
            <th *ngFor="let item of this.section;let i=index;">{{item }}</th>
            <th>Total Score</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="section.length">
            <ng-container *ngFor="let sector of sector">
              <tr>
                <th [attr.colspan]="this.section.length+1">{{sector}}</th>
              </tr>
              <tr *ngFor="let apps of app_ids[sector];let i=index;">
                <th class="headcol" style="position: sticky; top: 0; background-color: #D8E4BC;"
                  [class.rising-star]="this.compareDates(applicantData[criteria][sector][apps]?.applicantData[0]?.operationForm)">
                  {{ applicantData[criteria][sector][apps]['applicantData'][0]['firstName'] }}
                  {{
                  this.compareDates(applicantData[criteria][sector][apps]?.applicantData[0]?.operationForm) ?
                  '(Rising Star)' : ''
                  }}
                </th>
                <td class="long" *ngFor="let item of this.section;let i=index;">
                  {{ getRange(counted_total[sector][apps][item]) }}
                </td>
                <td>{{ getTotal(counted_total[sector][apps]) }}</td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>

  </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="mdl-header">
        <h5 class="modal-title" id="staticBackdropLabel">Sectors</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="bb-0 list-style-none sub-checklist">
          <li class="nav-tabs-item" role="presentation" data-dismiss="modal" [class.active]="sector.includes(sct)"
            *ngFor="let sct of sectorArray | slice:5" (click)="sectorChange(sct)">
            <a style="font-size: 14px;">
              {{ sct }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>