<app-header></app-header>
<app-sidenav *ngIf="this.udata.role!='applicant'"></app-sidenav>
<app-sidenav-applicant *ngIf="this.udata.role=='applicant'"></app-sidenav-applicant>
<div id="content" class="app-content" role="main">
  <div class="app-content-body container">
    <h2>Joint Applicant</h2>
    <div class="card">
      <div class="detail 1">
        <div class="row primary">
          <div class="col-md-12">
            <h4>Name of the Nominating (Customer) Organisation:</h4>
          </div>
          <div class="col-md-12">
            <p>Herbalife International India Pvt. Ltd.</p>
          </div>
        </div>
      </div>
      <div class="detail 2">
        <div class="row primary">
          <div class="col-md-12">
            <h4>Name of the Highest Ranking Official of the (Customer) Organisation:</h4>
          </div>
          <div class="col-md-12">
            <p>Atanu Haldar</p>
          </div>
        </div>
        <div class="row secondary">
          <div class="col-md-12">
            <h4>Designation:</h4>
          </div>
          <div class="col-md-12">
            <p>Sr Director, Product Operations - India</p>
          </div>
          <div class="col-md-12">
            <h4>Email:</h4>
          </div>
          <div class="col-md-12">
            <p>atanu@herbalife.com</p>
          </div>
        </div>
        <div class="row secondary">
          <div class="col-md-12">
            <h4>Telephone:</h4>
          </div>
          <div class="col-md-12">
            <p>+91-9972509065</p>
          </div>
        </div>
      </div>
      <div class="detail 3">
        <div class="primary">
          <h4>Name of the Contact Person in Nominating (Customer) Organisation:</h4>
          <h5>(All the communication will be sent to contact person)</h5>
        </div>
        <div class="person ">
          <div class="row secondary">
            <div class="col-md-12">
              <h4>Name:</h4>
            </div>
            <div class="col-md-12">
              <p>Latika Singh</p>
            </div>
            <div class="col-md-12">
              <h4>Designation:</h4>
            </div>
            <div class="col-md-12">
              <p>Assistant Manager- QA- India</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h4>Mobile:</h4>
            </div>
            <div class="col-md-12">
              <p>+91-6366749693</p>
            </div>
            <div class="col-md-12">
              <h4>Telephone</h4>
            </div>
            <div class="col-md-12">
              <h4>Email:</h4>
            </div>
            <div class="col-md-12">
              <p>latikas@herbalife.com</p>
            </div>
          </div>
        </div>
        <div class="person">
          <div class="row">
            <div class="col-md-12">
              <h4>Name:</h4>
            </div>
            <div class="col-md-12">
              <p>Vineet Aggarwal</p>
            </div>
            <div class="col-md-12">
              <h4>Designation:</h4>
            </div>
            <div class="col-md-12">
              <p>Sr Manager, Quality - India</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h4>Mobile:</h4>
            </div>
            <div class="col-md-12">
              <p>+91-9816093368</p>
            </div>
            <div class="col-md-12">
              <h4>Telephone</h4>
            </div>
            <div class="col-md-12">
              <h4>Email:</h4>
            </div>
            <div class="col-md-12">
              <p>vineeta@herbalife.com</p>
            </div>
          </div>
        </div>
      </div>
      <div class="detail 4">
        <h4>Address of Nominating (Customer) Organisation:</h4>
        <p> RMZ Pinnacle, No. 15, Commissariat Road, Bangalore,Karnataka, India - 560025</p>
      </div>
      <div class="detail 5">
        <h4>Product and Services Offered by the Nominating (Customer) Organisation:</h4>
        <p> Marketer of weight management, target nutrition and sports nutrition products including Nutraceutical,
          Health
          Supplements,
          Foods for Special Dietary Use for Weight Control and Management and Proprietary food products</p>
      </div>
      <div class="detail 6">
        <h4>Food Category Name and Number under which FSSAI License has been issued:</h4>
        <p>FSSAI License Number – 10013043000639</p>
        <p>01 - Dairy products and analogues, excluding products of food category 2.0</p>
        <p>06 - Cereals and cereal products, derived from cereal grains, from roots and tubers, pulses, legumes and pith
          or
          soft core of palm tree, excluding bakery wares of food category 7.0</p>
        <p>13 - Foodstuffs intended for particular nutritional uses</p>
        <p>14 - Beverages, excluding dairy products</p>
      </div>
      <div class="detail 7">
        <h4>Total Number of Employees in the Nominating (Customer) Organisation:</h4>
        <p>278</p>
      </div>
      <div class="detail 8">
        <h4>Annual Sales turnover of the Nominating (Customer) Organisation for previous year (INR/USD):</h4>
        <p>Approx. 3000 crores for 2020-2021. 2022-2023 figures are yet to be audited.</p>
      </div>
      <div class="detail 9">
        <h4>Name of Supplier/Co-Packers/Franchise/3rd Party Applicant Organisation:</h4>
        <p>Zeon Lifesciences Ltd.-Healthcare-2</p>
      </div>
      <div class="detail 10">
        <h4>Address of Supplier/Co-Packers/Franchise/3rd Party Applicant Unit:</h4>
        <p>Village Kunja Rampur Road Paonta sahib, Dist.- Sirmaur 173025,(H.P.)</p>
      </div>
      <div class="detail 11">
        <div class="row primary">
          <div class="col-md-12">
            <h4>Name of the Highest Ranking Official of the Supplier/Co-Packers/Franchise/3rd Party Applicant Unit:</h4>
          </div>
        </div>
        <div class="row secondary">
          <div class="col-md-12">
            <h4>Name:</h4>
          </div>
          <div class="col-md-12">
            <p>Mr. Suresh Garg</p>
          </div>
          <div class="col-md-12">
            <h4>Designation:</h4>
          </div>
          <div class="col-md-12">
            <p>Managing Director</p>
          </div>
        </div>
        <div class="row secondary">
          <div class="col-md-12">
            <h4>Mobile:</h4>
          </div>
          <div class="col-md-12">
            <p>9810182413</p>
          </div>
          <div class="col-md-12">
            <h4>Telephone: Nil</h4>
          </div>
          <div class="col-md-12">
            <h4>Email:</h4>
          </div>
          <div class="col-md-12">
            <p>suresh.garg@zeon.co.in</p>
          </div>
        </div>
      </div>
      <div class="detail 12">
        <div class="primary">
          <h4>Name of the Contact Person in Supplier/Co-Packers/Franchise/3rd Party Applicant Unit:</h4>
          <h5>(All the communication will be sent to contact person)</h5>
        </div>
        <div class="person ">
          <div class="row secondary">
            <div class="col-md-12">
              <h4>Name:</h4>
            </div>
            <div class="col-md-12">
              <p>Harshita Namdeo</p>
            </div>
            <div class="col-md-12">
              <h4>Designation:</h4>
            </div>
            <div class="col-md-12">
              <p>Manager QA</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h4>Mobile:</h4>
            </div>
            <div class="col-md-12">
              <p>8894729657</p>
            </div>
            <div class="col-md-12">
              <h4>Telephone</h4>
            </div>
            <div class="col-md-12">
              <h4>Email:</h4>
            </div>
            <div class="col-md-12">
              <p>qa5@zeon.co.inqa5@zeon.co.in</p>
            </div>
          </div>
        </div>

      </div>
      <div class="detail 13">
        <h4>13. Supplier/Co-Packers/Franchise/3rd Party Applicant Unit is in operation from:</h4>
        <p>[Date / Month/ Year]</p>
        <h4>Contact Address (If different from above):</h4>
        <p>Same as above</p>
      </div>
      <div class="detail 14">
        <h4>Product and Services Offered by the Supplier/Co-Packers/Franchise/3rd Party Applicant Unit:</h4>
        <p>Storage, Dispensing, Manufacturing, filling, packing & testing of RM /PM/FG</p>
      </div>
      <div class="detail 15">
        <h4>Food Category Name and Number under which FSSAI License has been issued:</h4>
        <p>Foodstuffs Intended for particular nutritional uses Category 13</p>
      </div>
      <div class="detail 16">
        <h4>Total Number of Employees in the Supplier/Co-Packers/Franchise/3rd Party Applicant Unit:</h4>
        <p>400 approx.</p>
      </div>
      <div class="detail 17">
        <h4>Investment in Plant & Machinery of the Supplier Unit (for Manufacturing):</h4>
        <p>17 Cr. approx.</p>
      </div>
      <div class="detail 18">
        <h4>Annual Sales turnover of the Supplier/Co-Packers/Franchise/3rd Party Applicant unit for previous year
          (INR/USD)
          2021 - 22:</h4>
        <p>For Herbalife - 46 Cr.</p>
      </div>
      <div class="detail 19">
        <h4>Criteria of Assessment of the Supplier/Co-Packers/Franchise/3rd Party Applicant Unit (as per Table 1
          attached):
        </h4>
        <p>Criteria 7 & Criteria 3 for SMB</p>
      </div>
      <div class="detail 20">
        <h4>Details for Raising Invoice:</h4>
        <h4>Zeon Lifesciences</h4>
        <ul>
          <li>
            <p>GST No:_ 02AAACH0860L7ZQ</p>
          </li>
          <li>
            <p>Address: Village Kunja, Rampur Road, Paonta Sahib, Sirmaur, Himachal Pradesh, 173025</p>
          </li>
          <li>
            <p>Whether Proforma / Tax Invoice is required: Yes</p>
          </li>
        </ul>
        <h4>Herbalife International</h4>
        <ul>
          <li>
            <p>GST No: 29AAACH8025R1ZZ</p>
          </li>
          <li>
            <p>Address: Herbalife International India Pvt. Ltd., Vaswani Wilshire, No 14, Commissariat Road, Bangalore,
              KARNATAKA 560025, India</p>
          </li>
          <li>
            <p>Whether Proforma / Tax Invoice is required: Yes</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>