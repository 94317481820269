import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aico',
  templateUrl: './aico.component.html',
  styleUrls: ['./aico.component.css']
})
export class AicoComponent implements OnInit {
  udata: any;

  constructor() {}

  ngOnInit(): void {
    this.udata = localStorage.getItem('userdata');
    this.udata = JSON.parse(this.udata);
  }
}


