import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { AllocationService } from 'src/app/services/allocation.service';
import { ApplicantService } from 'src/app/services/applicant.service';
import { QuestionService } from 'src/app/services/question.service';
import { TosterService } from 'src/app/services/toster.service';
import { noWhitespaceValidator } from 'src/shared/whitespace.validator';
import { CoApplicantService } from 'src/app/services/co-applicant.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-teamallocation',
  templateUrl: './teamallocation.component.html',
  styleUrls: ['./teamallocation.component.css'],
})
export class TeamallocationComponent implements OnInit {
  udata: any;
  allocationId: any;
  allocatedlist: any;
  criteria: any;
  criterias: any = [];
  app_email: any;
  section: { [key: string]: string[] } = {};
  selectedItems: any = [];
  dropdownSettings = {};
  min: any = new Date();
  to: any;
  subsecs: any = {};
  sectionForm: any = [];
  max: any;
  isLeader: boolean = false;
  setting: any = [];
  teamAllocation!: FormGroup;
  applicant_id: any;
  mindate: any = new Date();
  constructor(
    public allocation: AllocationService,
    private _Activatedroute: ActivatedRoute,
    private quest: QuestionService,
    private applicant: ApplicantService,
    private fb: FormBuilder,
    private toast: TosterService,
    public datepipe: DatePipe,
    private router: Router,
    private coapp: CoApplicantService,
  ) { }

  // dateTransform(value: Date, format: string) {
  //   return this.datepipe.transform(value, format)
  // }

  // get toDate() {
  //   return this.dateTransform(this.min, 'yyyy-MM-dd');
  // }

  ngOnInit(): void {
    this.udata = localStorage.getItem('userdata');
    this.udata = JSON.parse(this.udata);
    this.allocationId = this._Activatedroute.snapshot.paramMap.get('id');
    this.selectedItems = [];

    this.allocation
      .getAllAllocationByAssessorOne(this.allocationId)
      .subscribe((item: any) => {
        console.error(item, 'allocation data');
        this.allocatedlist = item.result;
        this.formInit(this.allocatedlist[0]);
        this.min = this.allocatedlist[0].period_from;
        this.to = this.allocatedlist[0].period_from;
        this.getDate(this.allocatedlist[0].period_from, 'load');
        this.getDateto(this.allocatedlist[0].period_to, 'load');
        console.log(this.allocatedlist)

        item.result.map((kk: any) => {

          if (kk.assessor_id == this.udata._id) {

            if (!kk.teamleader) {

              this.isLeader = true;

              this.router.navigate(['/assessors-assess-information']);
            }
          }
          this.setting.push({
            singleSelection: false,
            text: 'Select Countries',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true,
            showCheckbox: true,
          });
        });
        this.applicant_id = item.result[0].applicantData[0]._id;
        this.applicant
          .GetAdminApplicantSingle(item.result[0].applicantData[0]._id)
          .subscribe((data: any) => {
            if (
              data.applicanData[0].criteria != '' &&
              data.applicanData[0].userStatus === true
            ) {
              this.criteria = data.applicanData[0].criteria;
              this.coapp.listCoApplicant(data.applicanData[0]._id).subscribe((resp: any) => {
                if (resp.result.length) {
                  let criterias = resp.result.map((coapps: any) => {
                    return coapps.co_criteria
                  })
                  this.criterias = [data.applicanData[0].criteria].concat(criterias)
                  this.criterias = Array.from(new Set(this.criterias))
                } else {
                  this.criterias = [data.applicanData[0].criteria]
                }
                this.app_email = data.applicanData[0].email;
                for (let [index, criteria] of this.criterias.entries()) {
                  this.quest
                    .viewQuestionSec({ criteria: criteria })
                    .subscribe((item: any) => {
                      let ind = "Applicant";
                      if (index == 1) {
                        ind = "Co-Applicant"
                      }
                      this.section[ind] = Object.keys(item.sections);
                      this.subsecs[ind] = item.sections;
                      this.section[ind].sort((a: any, b: any) => a.localeCompare(b));
                    });
                }
              });
            } else {
            }
          });
      });
  }

  formInit(fdata: any) {
    this.teamAllocation = this.fb.group({
      period_from: [fdata.period_from, [Validators.required, noWhitespaceValidator]],
      period_to: [fdata.period_from, [Validators.required, noWhitespaceValidator]],
    });
  }

  getDate(d: any, by: any) {
    if (by == 'load') {
      this.min = d;
    } else {
      this.min = d.target.value;
      this.to = '';
      this.getDateto('', 'load')
      this.teamAllocation.controls['period_to'].setValue(undefined);
    }
  }
  getDateto(d: any, by: any) {
    if (by == 'load') {
      this.to = d;
    } else {
      this.to = d.target.value;
    }
  }

  preventKeyboardInput(event: KeyboardEvent): void {
    event.preventDefault();
  }

  updateAllocation() {
    if (!this.allocatedlist[0].period_from) {
      Swal.fire({
        text: 'Are you sure you want to save? You will not be able to change allocation.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          this.teamAllocation.value.period_from = this.min;
          this.teamAllocation.value.period_to = this.to;
          this.allocatedlist[0].period_from = this.min;
          this.allocatedlist[0].period_to = this.to;
          this.allocation
            .updatePeriodInAllocation(
              this.allocatedlist[0].allocation_id,
              this.teamAllocation.value
            )
            .subscribe((results: any) => {
              this.toast.showSuccessMsg('Allocation Time Period Updated');
            });
        }
      });
    } else {
      this.teamAllocation.value.period_from = this.min;
      this.teamAllocation.value.period_to = this.to;
      this.allocatedlist[0].period_from = this.min;
      this.allocatedlist[0].period_to = this.to;
      this.allocation
        .updatePeriodInAllocation(
          this.allocatedlist[0].allocation_id,
          this.teamAllocation.value
        )
        .subscribe((results: any) => {
          this.toast.showSuccessMsg('Allocation Time Period Updated');
        });
    }
  }
  checksub(id: any, subsec: any, type: string) {
    for (const item of this.allocatedlist) {
      if (item._id === id) {
        if (type == "Applicant") {
          if (item.sub_section && item.sub_section.includes(subsec)) {
            return true;
          }
        } else if (type == "Co-Applicant") {
          if (item.cosub_section && item.cosub_section.includes(subsec)) {
            return true;
          }
        }
      } else {
        if (type == "Applicant") {
          if (item.sub_section && item.sub_section.includes(subsec)) {
            return false;
          }
        } else if (type == "Co-Applicant") {
          if (item.cosub_section && item.cosub_section.includes(subsec)) {
            return false;
          }
        }
      }
    }
    return false;
  }

  checkdissub(id: any, subsec: any, type: any) {
    if (this.allocatedlist[0].period_from) {
      return true;
    }
    for (const item of this.allocatedlist) {
      if (item._id != id) {
        if (type == "Applicant") {
          if (item.sub_section && item.sub_section.includes(subsec)) {
            return true;
          }
        } else if (type == "Co-Applicant") {
          if (item.cosub_section && item.cosub_section.includes(subsec)) {
            return true;
          }
        }
      }
    }
    return false;
  }
  get colspanValue() {
    return Object.keys(this.section).length;
  }
  checkToShow(assessor: any, item: any, type: string) {
    if (type == "Applicant") {
      return assessor.section && assessor.section.includes(item)
    } else if (type == "Co-Applicant") {
      return assessor.cosection && assessor.cosection.includes(item)
    }
  }

  updateSection(e: any, id: any, i: any, subsecs: any, type: any) {
    var subs = [...subsecs];
    if (e.target.checked) {
      for (const item of this.allocatedlist) {
        if (item._id != id) {
          if (type == "Applicant") {
            for (const subsec of subs) {
              if (item.sub_section && item.sub_section.includes(subsec)) {
                subs = subs.filter(s => s !== subsec);
              }
            }
          } else if (type == "Co-Applicant") {
            for (const subsec of subs) {
              if (item.cosub_section && item.cosub_section.includes(subsec)) {
                subs = subs.filter(s => s !== subsec);
              }
            }
          }
        }
      }
    }
    let data: any = {
      status: e.target.checked
    }
    if (type == "Applicant") {
      data['section'] = e.target.value
      data['subsecs'] = subs
    } else if (type == "Co-Applicant") {
      data['cosection'] = e.target.value
      data['cosubsecs'] = subs
    }

    this.allocation
      .updateSectionInAllocation(id, data)
      .subscribe((result: any) => {
        this.allocatedlist[i].section = result.updateApplicant.section;
        this.allocatedlist[i].sub_section = result.updateApplicant.sub_section;
        this.allocatedlist[i].cosection = result.updateApplicant.cosection;
        this.allocatedlist[i].cosub_section = result.updateApplicant.cosub_section;
        this.toast.showSuccessMsg('Section Updated');
      });
  }

  amIChecked(assessor: any, section: any, type: any) {
    if (type == 'Applicant') {
      return assessor.section?.includes(section)
    }
    return assessor.cosection?.includes(section)
  }

  updateSubSection(e: any, id: any, i: any, type: string) {
    let data: any = {
      status: e.target.checked
    }
    if (type == "Applicant") {
      data['subsec'] = e.target.value
    } else if (type == "Co-Applicant") {
      data['cosubsec'] = e.target.value
    }
    this.allocation
      .updateSubSectionInAllocation(id, data)
      .subscribe((result: any) => {
        this.allocatedlist[i].sub_section = result.updateApplicant.sub_section;
        this.allocatedlist[i].cosub_section = result.updateApplicant.cosub_section;
        this.toast.showSuccessMsg('Sub-section updated');
      });
  }

}
