<app-header></app-header>
<app-sidenav *ngIf="this.udata.role!='assessor'"></app-sidenav>
<app-sidenav-assessors *ngIf="this.udata.role=='assessor'"></app-sidenav-assessors>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Hold on while we take you to your CII FACE Account! </p>
</ngx-spinner>
<div id="content" class="app-content" role="main">
  <div class="app-content-body mar-in-20">
    <div>
      <h2>Grand Tally Report</h2>
    </div>
    <ul class="nav nav-tabs bb-1" *ngIf="criteriaArray.length > 1">
      <li class="nav-item" role="presentation" *ngFor="let crt of criteriaArray" [class.active]="criteria == crt"
        (click)="criteriaChange(crt)">
        <a>{{crt}}</a>
      </li>
    </ul>
    <div class="clearfix"></div>

    <div>
      <ul class="d-flex bb-0 list-style-none sub-checklist">
        <li class="nav-tabs-item" role="presentation" [class]="item==this.section_no?'active':''"
          *ngFor="let item of this.section | slice:0:6" (click)="getscore(item)"><a style="font-size: 14px;">Sec-{{
            item }}</a>
        </li>
        <li class="nav-tabs-item" data-toggle="modal" data-target="#exampleModal" role="presentation"
          *ngIf="this.section.length>6"><a style="font-size: 14px;">See More</a>
        </li>
      </ul>
    </div>

    <div class="lock-header">
      <table class="table table-borderless">
        <thead>
          <tr>
            <th class="headcol" style="position: sticky; 
            top: 0; 
            background-color: #87A63F; color: white;">Sub Section</th>
            <th *ngFor="let apps of app_ids; let i=index" style="background: #EAEAEA;">
              {{ applicantData[criteria][apps]['applicantData'][0]['firstName'] + ", " +
              applicantData[criteria][apps]['applicantData'][0]['applicantCity'] }}
            </th>
          </tr>
          <tr>
            <th class="headcol" style="position: sticky; 
            top: 0; 
            background-color: #87A63F; color: white;">Rising Star</th>
            <th class="text-center" *ngFor="let apps of app_ids; let i=index"
              [class.rising-star]="this.compareDates(applicantData[criteria][apps]['applicantData'][0]?.operationForm)">
              {{ this.compareDates(applicantData[criteria][apps]['applicantData'][0]?.operationForm) ? 'Yes' : 'No' }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let itemd of subSections;let i=index">
            <th class="headcol" style="position: sticky; top: 0; background-color: #F8FDEC;">
              {{ itemd?._id?.sub_section_title }}({{ itemd?._id?.sub_section_no }})
            </th>
            <ng-container *ngFor="let apps of app_ids;let i=index;">
              <td class="long text-center" [ngStyle]="getCellStyle(SubSecmodel[apps][itemd?._id?.sub_section_no])"
                *ngIf="SubSecmodel[apps]">
                {{ SubSecmodel[apps][itemd?._id?.sub_section_no] }}
              </td>
            </ng-container>
          </tr>
          <tr>
            <th class="headcol" style="position: sticky; top: 0; background-color: #F8FDEC;"><b>Section Score</b></th>
            <td class="long text-center" style=" background-color: #FFFF00;" *ngFor="let apps of app_ids;let i=index;">
              <b *ngIf="SubSecmodel && SubSecmodel[apps]">
                {{ SubSecmodel[apps]['total'] }}
              </b>
            </td>
          </tr>
          <tr>
            <th class="headcol" style="position: sticky; top: 0; background-color: #F8FDEC;"><b>Total Score</b></th>
            <td class="long text-center" style=" background-color: #92D050;" *ngFor="let apps of app_ids;let i=index;">
              <b>
                {{ (final_total[apps] / this.section.length) | number: '1.1-1' }}
              </b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="mdl-header">
        <h5 class="modal-title" id="staticBackdropLabel">Sub-sections</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="bb-0 list-style-none sub-checklist">
          <li class="nav-tabs-item" role="presentation" data-dismiss="modal" *ngFor="let item of section | slice:6"
            (click)="getscore(item)" [class]="item==section_no?'active':''">
            <a style="font-size: 14px;">
              Sec - {{ item }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>