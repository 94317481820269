<app-header></app-header>
<app-sidenav *ngIf="this.udata.role!='assessor'"></app-sidenav>
<app-sidenav-assessors *ngIf="this.udata.role=='assessor'"></app-sidenav-assessors>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Hold on while we take you to your CII FACE Account! </p>
</ngx-spinner>
<div id="content" class="app-content" role="main">
  <div class="app-content-body">
    <div class="d-flex align-items-center mar-in-20">
      <div class="col-md-10 my-3">
        <span style="color: #939393;" class="cursor-pointer"
          routerLink="{{udata.role.includes('admin') ? '/list-allocation' : '/assessors-assess-information'}}"><i
            class="fa fa-long-arrow-left" aria-hidden="true"></i>
          Assessment
          Information</span>
        <h2 class="mt-0">Score Summary Sheet</h2>
      </div>
      <div class="col-md-2">
        <app-commonhead [allocation_id]="this.allocated_id" [applicant_id]="this.applicant_id"></app-commonhead>
      </div>
    </div>
    <div class="clearfix"></div>
    <ul class="nav nav-tabs bb-1" *ngIf="criteria && criteria.length > 1">
      <li class="nav-item" role="presentation" (click)="switchTab('applicant')"
        [class.active]="activeTab == 'applicant'">
        <a>Applicant</a>
      </li>
      <li class="nav-item" role="presentation" (click)="switchTab('coapplicant')"
        [class.active]="activeTab == 'coapplicant'">
        <a>Co-Applicant</a>
      </li>
    </ul>
    <div>
      <ul class="d-flex bb-0 list-style-none sub-checklist">
        <li role="presentation" class="nav-tabs-item" [class.active]="item._id.section_no==this.section_no"
          *ngFor="let item of this.section" (click)="getscore(item._id.section_no)"><a style="font-size: 14px;">Sec-{{
            item._id.section_no }}</a>
        </li>
      </ul>
    </div>

    <div *ngIf="subSections.length" class="mar-in-20">
      <p class="text-danger" style="margin-left:2rem ;margin-top: 1rem;"><i class="fa fa-info-circle"></i> Select
        checkbox if any sub section is Not Applicable in this assessment</p>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>NA</th>
            <th>Sub Section</th>
            <th>Highest Score </th>
            <th>Evaluated Score</th>
            <th>Score (%)</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let itemd of subSections;let i=index">
            <td>
              <span *ngIf="checkValue(itemd._id.sub_section_no,this.SubSecmodel[itemd._id.sub_section_no])"
                class="na-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="red" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" style="width: 24px; height: 24px;">
                  <circle cx="12" cy="12" r="10" />
                  <line x1="15" y1="9" x2="9" y2="15" />
                  <line x1="9" y1="9" x2="15" y2="15" />
                </svg>
              </span>
            </td>
            <td>
              {{ itemd._id.sub_section_title }}({{ itemd._id.sub_section_no }})
            </td>
            <td>
              <input class="form-control" [(ngModel)]="HighScrmodel[itemd._id.sub_section_no]" readonly />
            </td>
            <td><input class="form-control"
                [value]="((this.SubSecmodel[itemd._id.sub_section_no]/100)*this.HighScrmodel[itemd._id.sub_section_no]).toFixed(1)"
                readonly /></td>
            <td><input class="form-control" [(ngModel)]="SubSecmodel[itemd._id.sub_section_no]" readonly /></td>
          </tr>
          <tr>
            <td colspan="2">Total</td>
            <td>{{ highScore }}</td>
            <td>{{ count.toFixed(2) }}</td>
            <td>--</td>
          </tr>
        </tbody>

        <p *ngIf="section_no"><button type="button" class="btn btn-success btn-sm pull-right m" *ngIf="tl"
            (click)="generateScore()">Generate
            Final Score</button></p>
      </table>
    </div>


  </div>
</div>