import { Component, OnDestroy, OnInit } from '@angular/core';
import { AssessorsService } from 'src/app/services/assessors.service';
import { TosterService } from 'src/app/services/toster.service';
import { Form, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
// import '../rxjs/add/operator/map';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { noWhitespaceValidator } from 'src/shared/whitespace.validator';



@Component({
  selector: 'app-assessors-view',
  templateUrl: './assessors-view.component.html',
  styleUrls: ['./assessors-view.component.css'],
})
export class AssessorsViewComponent implements OnDestroy, OnInit {
  assessors: any;
  assessorsForm: any;
  AdminEmail: any
  role: any;
  selectedFileName: string = '';

  isValidFormSubmitted: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  score: number = 0;
  rating: number = 0;
  assessors_id: any;
  updateAssessorScoreForm!: FormGroup;
  rank: number = 0;
  rows: any; //excel data
  constructor(
    private fb: FormBuilder,
    private assessorsS: AssessorsService,
    private toast: TosterService,
    private auth: AuthService,
    private location: Location
  ) { }
  term: any;

  ngOnInit(): void {
    this.AdminEmail = localStorage.getItem('user')
    this.role = localStorage.getItem('role')
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: false,
      searching: true,
      dom: 'lBfrtip',
    };

    this.assessorsS.getAssessors().subscribe((data: any) => {
      this.assessors = data.applicanData;

      this.dtTrigger.next();
    });
    this.onformUpdate();
  }

  public show: boolean = false;
  public buttonName: any = true;
  toggle() {
    this.show = !this.show;
    if (this.show) this.buttonName = false;
    else this.buttonName = true;
  }

  onFileSelected(event: any, fileInput: HTMLInputElement) {
    const file: File = event.target.files[0];
    fileInput.value = '';
    this.selectedFileName = file.name;
    const confirmation = confirm("Are you sure you want to import " + this.selectedFileName + "?");
    if (confirmation) {
      this.parseExcel(file);
    }
  }


  parseExcel(file: File) {
    const reader: FileReader = new FileReader();
    const combinedArray: any[] = [];
    reader.onload = (e: any) => {
      const binaryString: string = e.target.result;
      const workbook: XLSX.WorkBook = XLSX.read(binaryString, { type: 'binary' });
      const worksheetName: string = workbook.SheetNames[0];
      const worksheet: XLSX.WorkSheet = workbook.Sheets[worksheetName];
      const jsonData: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      this.rows = jsonData.slice(1); // Exclude header row
      for (const row of this.rows) {
        // const combinedRow = `${row[0]}, ${row[1]}, ${row[2]}`;
        const combinedRow = [];
        if (row[0] == undefined || row[0] == null || row[0] == '') {
          row[0] = '';
        }
        if (row[1] == undefined || row[1] == null || row[1] == '') {
          row[1] = '';
        }
        if (row[2] == undefined || row[2] == null || row[2] == '') {
          row[2] = '';
        }

        if (row[3] == undefined || row[3] == null || row[3] == '') {
          row[3] = '';
        }

        if (row[4] == undefined || row[4] == null || row[4] == '') {
          row[4] = '';
        }
        combinedRow.push(row[0]);
        combinedRow.push(row[1]);
        combinedRow.push(row[2]);
        combinedRow.push(row[3]);
        combinedRow.push(row[4]);
        combinedArray.push(combinedRow);
      }

      const jsonOutput = JSON.stringify(combinedArray);
      var jsonOutput2 = {
        data: 'test'
      }
      this.assessorsS.importAssessor(combinedArray).subscribe(
        (response) => {

          window.location.reload();
          // this.ngOnInit();
          // Handle the response data here
        },
        (error) => {
          console.error('Error:', error);
          // Handle the error here
        }
      );

    };
    reader.readAsBinaryString(file);

  }


  onformUpdate() {
    this.updateAssessorScoreForm = this.fb.group({
      score: [this.score, [noWhitespaceValidator]],
      rating: [this.rating, [noWhitespaceValidator]],
      rank: [this.rank, [noWhitespaceValidator]],
    });
  }

  updateScore() {
    this.updateAssessorScoreForm.value.score = this.score;
    this.updateAssessorScoreForm.value.rating = this.rating;
    this.updateAssessorScoreForm.value.rank = this.rank;

    this.assessorsS
      .updateScore(this.updateAssessorScoreForm.value, this.assessors_id)
      .subscribe((result: any) => {


      });
  }

  onforminit() {
    this.assessorsForm = this.fb.group({
      zone: ['', [noWhitespaceValidator]],
      batch: ['', [noWhitespaceValidator]],
      designation: ['', [noWhitespaceValidator]],
      presentCo: ['', [noWhitespaceValidator]],
      pastCo: ['', [noWhitespaceValidator]],
      fsmsCertificate: ['', [noWhitespaceValidator]],
      exposure: ['', [noWhitespaceValidator]],
      title: ['', [noWhitespaceValidator]],
      firstName: ['', [noWhitespaceValidator]],
      lastName: ['', [noWhitespaceValidator]],
      phone: ['', [noWhitespaceValidator]],
      email: ['', [noWhitespaceValidator]],
      education: ['', [noWhitespaceValidator]],
      year: ['', [noWhitespaceValidator]],
      skill: ['', [noWhitespaceValidator]],
      domain: ['', [noWhitespaceValidator]],
      addressLine1: ['', [noWhitespaceValidator]],
      addressLine2: ['', [noWhitespaceValidator]],
      zipCode: ['', [noWhitespaceValidator]],
      country: ['', [noWhitespaceValidator]],
      state: ['', [noWhitespaceValidator]],
      city: ['', [noWhitespaceValidator]],
    });
  }

  reSendEoi(id: any, email: any) {
    // if (this.assessorsForm.invalid) {
    //   
    //   this.isValidFormSubmitted = true;
    // } else {

    var source = 'staging';
    let url = '/assessorReSendEOI/' + email;

    this.assessorsS
      .assessorReSendEOI({ email: email }, id, source)
      .subscribe((data: any) => {

        let url = '/assessorReSendEOI/' + id;

        this.toast.showSuccess('Resend EOI Successfully!.');
        window.location.reload();
      });
    // }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();

  }

  decValue() {
    if (this.score <= 0) {
      this.score = 0;
    } else {
      --this.score;
    }
  }

  incValue() {
    if (this.score < 10)
      ++this.score;
  }
  loginAs(assessorData: any) {
    if (this.role) {
      let userData: any = localStorage.getItem('userdata')
      userData = JSON.parse(userData)
      this.auth.loginAsAssessor({ email: this.AdminEmail, role: this.role, assessorEmail: assessorData?.assessorsEmail, password: userData?.password })?.subscribe((user: any) => {
        localStorage.clear()
        localStorage.setItem('username', user.results?.username);
        localStorage.setItem('user', user.results?.email);
        localStorage.setItem('role', user.results?.role);
        localStorage.setItem('userdata', JSON.stringify(user?.results));
        window.open(
          '/assessors', "_blank");
        window.close()
        // setTimeout(() => {
        //   // this.router.navigate(['/assessors']);
        //   window.open( 
        //     '/assessors', "_blank");

        //   // window.location.href = '/assessors';
        //   
        // }, 1500);
      })

    } else {
      this.toast.showError('Please verify that you are not a robot.');
      // this.captchaError = 'Please verify that you are not a robot.';
      // this.spinner.hide();
    }
    console.log(assessorData)
  }

  mainesValue() {
    if (this.rating <= 0) {
      this.rating = 0;
    } else {
      --this.rating;
    }
  }
  plussValue() {
    if (this.rating < 10)
      ++this.rating;
  }

  addScore(id: any) {
    this.assessors_id = id;
  }

  decValue1() {
    if (this.rank <= 0) {
      this.rank = 0;
    } else {
      --this.rank;
    }
  }

  incValue1() {
    if (this.rank < 10)
      ++this.rank;
  }
  checkNumber(t: any, f: any) {
    if (t.target.value > 0 && t.target.value <= 10) {
      if (f == 's1') {
        this.score = t.target.value;
      }
      if (f == 's2') {
        this.rating = t.target.value;
      }
      if (f == 's3') {
        this.rank = t.target.value;
      }
    }
    else {
      if (f == 's1') {
        this.score = 0;
      }
      if (f == 's2') {
        this.rating = 0;
      }
      if (f == 's3') {
        this.rank = 0;
      }
    }
  }


  deleteAssessor(id: string): void {

    Swal.fire({
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.assessorsS.deleteAssessor(id).subscribe({
          next: (resp: any) => {
            if (resp.success) {
              Swal.fire('Assessor deleted successfully.', '', 'success');
              window.location.reload();
            } else {
              Swal.fire(resp.message, '', 'error')
            }
          },
          error: (err: any) => {

          }
        })
      } else if (result.isDismissed) {

      }
    });

  }
}
