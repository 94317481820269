<app-header></app-header>
<app-sidenav-assessors></app-sidenav-assessors>
<div id="content" class="app-content" role="main">
  <div class="app-content-body container">
    <h2>Resources</h2>
    <div class="card">
      <table class="table">
        <thead>
          <tr>
            <th>Sno.</th>
            <th>Brochure Name</th>
            <th><i class="fa fa-download"></i> Download</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-left">1</td>
            <td class="text-left">CII FACE Award - Brochure</td>
            <td class="text-left text-info"><a href="../../../assets/pdf/Brochure.pdf" target="_blank"><i
                  class="fa fa-download"></i> Download</a></td>
          </tr>
          <tr>
            <td class="text-left">2</td>
            <td class="text-left">CII FACE - Organization Criteria Table</td>
            <td class="text-left text-info"><a href="../../../assets/pdf/Criteria.pdf" target="_blank"><i
                  class="fa fa-download"></i> Download</a></td>
          </tr>
          <tr>
            <td class="text-left">3</td>
            <td class="text-left">CII FACE - Applicant Fee Structure</td>
            <td class="text-left text-info"><a href="../../../assets/pdf/Fee.pdf" target="_blank"><i
                  class="fa fa-download"></i>
                Download</a></td>
          </tr>
          <tr>
            <td class="text-left">4</td>
            <td class="text-left">Key Steps for Site Visit - 2024</td>
            <td class="text-left text-info"><a href="../../../assets/pdf/Key-Steps-for-Site-Visit-2024-updated.docx"
                target="_blank"><i class="fa fa-download"></i> Download</a></td>
          </tr>
          <tr>
            <td class="text-left">5</td>
            <td class="text-left">Roles & Responsibilities</td>
            <td class="text-left text-info"><a
                href="../../../assets/pdf/Roles-Responsibilities-of-Assessor-Team-2024.pptx" target="_blank"><i
                  class="fa fa-download"></i> Download</a></td>
          </tr>
          <tr>
            <td class="text-left">6</td>
            <td class="text-left">Site Visit Plan Sample Food Court 2024</td>
            <td class="text-left text-info"><a href="../../../assets/pdf/Site-Visit-Plan-Sample-Food-Court-2024.pptx"
                target="_blank"><i class="fa fa-download"></i>
                Download</a></td>
          </tr>
          <tr>
            <td class="text-left">7</td>
            <td class="text-left">Site Visit Plan Sample Food Testing Laboratory 2024</td>
            <td class="text-left text-info"><a
                href="../../../assets/pdf/Site-Visit-Plan-Sample-Food-Testing-Laboratory-2024.pptx" target="_blank"><i
                  class="fa fa-download"></i>
                Download</a></td>
          </tr>
          <tr>
            <td class="text-left">8</td>
            <td class="text-left">Site Visit Plan Sample Joint Application 2024</td>
            <td class="text-left text-info"><a
                href="../../../assets/pdf/Site-Visit-Plan-Sample-Joint-Application-2024.pptx" target="_blank"><i
                  class="fa fa-download"></i>
                Download</a></td>
          </tr>
          <tr>
            <td class="text-left">9</td>
            <td class="text-left">Site Visit Plan Sample Regular Assessments 2024</td>
            <td class="text-left text-info"><a
                href="../../../assets/pdf/Site-Visit-Plan-Sample-Regular-Assessments-2024.pptx" target="_blank"><i
                  class="fa fa-download"></i>
                Download</a></td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</div>