<app-header></app-header>
<app-sidenav></app-sidenav>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Hold on while we take you to your CII FACE Account! </p>
</ngx-spinner>
<div id="content" class="app-content" role="main">
  <div class="app-content-body mar-in-20">
    <h2>Checklist(Section)</h2>
    <ul class="nav nav-tabs bb-1" *ngIf="criteria && criteria.length > 1">
      <li class="nav-item" role="presentation" (click)="switchTab('applicant')"
        [class.active]="activeTab == 'applicant'">
        <a>Applicant</a>
      </li>
      <li class="nav-item" role="presentation" (click)="switchTab('coapplicant')"
        [class.active]="activeTab == 'coapplicant'">
        <a>Co-Applicant</a>
      </li>
    </ul>
    <div>
      <ul class="d-flex bb-0 list-style-none sub-checklist">
        <li role="presentation" class="nav-tabs-item" [class.active]="item.key==this.section_no"
          *ngFor="let item of this.section| keyvalue" (click)="getQuestion(item.key)"><a>Sec-{{
            item.key }}</a></li>
      </ul>
      <div class="col-sm-12 panel mt" *ngFor="let item of this.subsection">
        <p class="h4" style="margin: 2rem;">{{ item.sub_section_no }} : {{ item.sub_section_title }}</p>
        <div class="col-md-12 text-danger">
          <div class="col-md-3 b top">Qs#</div>
          <div class="col-md-6 b top">Checkpoint</div>
          <div class="col-md-3 b top">Answer</div>
        </div>
        <div class="col-md-12" *ngFor="let item_inner of this.questions">
          <div *ngIf="item.sub_section_no===item_inner.sub_section_no">
            <div class="col-md-3">{{ item_inner.question_no }}</div>
            <div class="col-md-6">{{ item_inner.question_title }}<br /><small>{{
                item_inner.question_description }}</small></div>
            <div class="col-md-3">{{item_inner.answer }}</div>
          </div>
        </div>


      </div>
    </div>

  </div>
</div>