<app-header></app-header>
<app-sidenav-applicant></app-sidenav-applicant>
<div id="content" class="app-content" role="main">
  <div class="app-content-body container">
    <h2>Resources</h2>
    <div class="card">
      <table class="table">
        <thead>
          <tr>
            <th>Sno.</th>
            <th>Brochure Name</th>
            <th><i class="fa fa-download"></i> Download</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-left">1</td>
            <td class="text-left">CII FACE Award - Brochure</td>
            <td class="text-left text-info"><a href="../../../assets/pdf/Brochure.pdf" target="_blank"><i
                  class="fa fa-download"></i> Download</a></td>
          </tr>
          <tr>
            <td class="text-left">2</td>
            <td class="text-left">CII FACE - Organization Criteria Table</td>
            <td class="text-left text-info"><a href="../../../assets/pdf/Criteria.pdf" target="_blank"><i
                  class="fa fa-download"></i> Download</a></td>
          </tr>
          <tr>
            <td class="text-left">3</td>
            <td class="text-left">CII FACE - Applicant Fee Structure</td>
            <td class="text-left text-info"><a href="../../../assets/pdf/Fee.docx" target="_blank"><i
                  class="fa fa-download"></i> Download</a></td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</div>