<!-- <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" /> -->

<script src="https://www.google.com/recaptcha/api.js" async defer></script>
<script>
  function validateForm() {

    let x = document.forms["myForm"]["g-recaptcha-response"].value;
    if (x == "") {
      alert("Captcha must be filled out");
      return false;
    }
  }
</script>

<!-- content1 -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Verify your email in the next form to receive password reset instructions. </p>
</ngx-spinner>

<section class="main-bg">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="food-img-bg">
          <div class="top-logo"> <img src="../../assets/img/CII-Logo.png" alt="" class="cii-logo"> <img
              src="../../assets/img/CII-Face.png" alt="" class="cii-face"> </div>
          <div class="main-bg-con">
            <div class="row g-0">
              <div class="col-lg-8">
                <div class="left-content">
                  <h3>Welcome to <br>Food Safety Award</h3>
                  <span>Assessment Portal</span>

                  <p>The Award is one of its kind and focuses on Food Safety Maturity Assessment to promote excellence.
                    Since its inception in 2010, the CII Food Safety Award has become a symbol of credibility and pride
                    for Food Business operators. More than recognition, the Awards measure performance and provide
                    detailed feedback from an external perspective on opportunities to excel.</p>

                  <p>Please use the <a href="/signUp">sign-up</a> form to create your application if you have received
                    invitation from CII Food & Agriculture Centre of Excellence (FACE).</p>
                  <h6>Awarding excellence in Food Safety!</h6>
                  <h5 class="moreDetail">For more details, please contact:</h5>
                  <h5>CII Food Safety Award Secretariat</h5>
                  <h5 class="colorBlue"><a href="mailto:fs.award@cii.in" target="_blank">fs.award@cii.in</a></h5>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="login-sec">
                  <h2>Forgot Password</h2>
                  <p>Enter your registered e-mail to receive instructions to reset your password.</p>
                  <div class="login-sec-con">
                    <form [formGroup]="appForm" method="post" (ngSubmit)="clickFunction()">
                      <div class="col-md-12">
                        <div class="form-group">
                          <input type="text" class="form-control user-bg email" id="email" placeholder="Email"
                            formControlName="email">
                        </div>

                        <div *ngIf="f.email.errors && isValidFormSubmitted"
                          class="text-danger text-left mb-1 errorcontrols">
                          <div *ngIf="f.email.errors.required"><small>Email is required</small></div>

                        </div>
                      </div>

                      <span class="msg-error error"></span>

                      <div class="col-md-12 form-group">
                        <button type="submit" class="btn btn-primary btn-lg btn-block login_btn"
                          [attr.disabled]="btnStatus" id="btn-validate">Verify</button>
                      </div>





                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="troo-logo"> <a href="https://www.troology.com/" target="_blank"><img src="../../assets/img/Footer.svg" alt=""></a> </div>-->
      </div>
    </div>
  </div>
</section>