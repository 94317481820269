import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ApplicantService } from 'src/app/services/applicant.service';
import { CoApplicantService } from 'src/app/services/co-applicant.service';
import { QuestionService } from 'src/app/services/question.service';
import { TosterService } from 'src/app/services/toster.service';

@Component({
  selector: 'app-assess-checklist',
  templateUrl: './assess-checklist.component.html',
  styleUrls: ['./assess-checklist.component.css'],
})
export class AssessChecklistComponent implements OnInit {
  applicant_id: any;
  section: any;
  copyToggleChecked = false;

  criteria: any;
  app_email: any;
  questions: any;
  floating: boolean = false;
  subsection: any;
  subsecs: any;
  answer: any;
  checkminmax: boolean = true;
  Sectionform!: FormGroup;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  public model: any = {};
  modall: any = [];
  public modelscore: any = {};
  public modelscorestatus: any = {};
  modallscore: any = [];
  udata: any;
  section_no: any;
  allocated_id: any;
  cosection: any = [];
  cosubsecs: any = [];
  activeTab: string = 'applicant';
  activeSubsection: string = '';
  constructor(
    private router: Router,
    private quest: QuestionService,
    private applicant: ApplicantService,
    private _Activatedroute: ActivatedRoute,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toast: TosterService,
    private coapp: CoApplicantService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.udata = localStorage.getItem('userdata');
    this.udata = JSON.parse(this.udata);
    let email = this.udata.email;
    this.applicant_id = this._Activatedroute.snapshot.paramMap.get('id');
    this.allocated_id = this._Activatedroute.snapshot.paramMap.get('_id');
    this.applicant
      .GetAdminApplicantSingle(this.applicant_id)
      .subscribe((data: any) => {
        if (
          data.applicanData[0].criteria != '' &&
          data.applicanData[0].userStatus === true
        ) {
          this.criteria = [data.applicanData[0].criteria];
          this.app_email = data.applicanData[0].email;
          this.quest
            .getAllocated({
              allocation_id: this.allocated_id,
              assessor_id: this.udata._id,
            })
            .subscribe((elem: any) => {
              this.section = elem[0].section;
              this.subsecs = elem[0].sub_section;
              this.cosection = elem[0].cosection;
              this.cosubsecs = elem[0].cosub_section;
              this.section = this.section.sort();
              this.subsecs = this.subsecs.sort();
              if (this.cosection) this.cosection = this.cosection.sort();
              if (this.cosubsecs) this.cosubsecs = this.cosubsecs.sort();
              this.coapp.listCoApplicant(data.applicanData[0]._id).subscribe((resp: any) => {
                if (resp.result.length) {
                  let criterias = resp.result[0].co_criteria;
                  this.criteria = this.criteria.concat(criterias)
                }
                this.spinner.hide();
              });
            });
        } else {

        }
        this.dtTrigger.next();
      });
  }

  formInit(model: any) {
    this.Sectionform = this.fb.group(model);
  }
  public get f() {
    return this.Sectionform.controls;
  }

  onSubmit() {
    let criteria = this.criteria[0]
    if (this.activeTab == 'coapplicant') {
      criteria = this.criteria[1];
    }
    this.Sectionform.patchValue(this.modall);
    this.quest
      .saveAssessment({
        assessment: this.Sectionform.value,
        section_no: this.section_no,
        email: this.udata.email,
        applicant_id: this.applicant_id,
        criteria: criteria,
        type: this.udata.role,
        copiedApplicant: this.copyToggleChecked
      })
      .subscribe((item: any) => {
        this.quest
          .addScore({
            assessment_id: item.assessment._id,
            allocation_id: this.allocated_id,
            applicant_id: this.applicant_id,
            assessor_id: this.udata._id,
            criteria: criteria,
            score: this.modelscore,
            applicable: this.modelscorestatus,
            scoreBy: this.udata.email,
          })
          .subscribe((itemRes: any) => {
            this.toast.showInfo('Section Saved.');
          });
      });
  }

  switchTab(userType: any) {
    this.activeTab = userType;
    this.floating = false
    this.model = {};
    this.modelscore = {};
    this.section_no = NaN;
    this.modelscorestatus = {};
    this.subsection = [];
    this.copyToggleChecked = false;
    this.activeSubsection = '';
  }

  getQuestion(sec: any) {
    let criteria = this.criteria[0];
    if (this.activeTab == 'coapplicant') {
      criteria = this.criteria[1];
    }
    this.floating = false
    this.model = {};
    this.modelscore = {};
    this.section_no = sec;
    this.activeSubsection = sec;
    this.modelscorestatus = {};
    this.subsection = [];
    this.spinner.show();
    this.quest
      .viewQuestionByCriteria({
        criteria: criteria,
        section_no: sec,
      })
      .subscribe((itemQues: any) => {
        itemQues.sec.map((element: any) => {
          if (this.subsecs.includes(element?.sub_section_no)) {
            this.model[element?._id] = '';
            this.modelscore[element?.sub_section_no] = 0;
            this.modelscorestatus[element?.sub_section_no] = false;
          }
        });

        this.quest
          .viewAssessment({
            section_no: this.section_no,
            email: this.udata.email,
            criteria: criteria,
            type: this.udata.role,
            applicant_id: this.applicant_id
          })
          .subscribe(async (ele: any) => {
            this.copyToggleChecked = false
            if (ele.ass.length == 1) {
              this.copyToggleChecked = ele.ass[0].copiedApplicant
              this.modall = await ele.ass[0].assessment[0];
              this.Sectionform = this.fb.group(this.modall);
              this.Sectionform.patchValue(this.modall)
              this.quest
                .getScore({
                  assessment_id: ele.ass[0]._id,
                })
                .subscribe((itemScore: any) => {
                  this.modelscore = itemScore[0]?.score[0];
                  this.modelscorestatus = itemScore[0]?.applicable
                    ? itemScore[0]?.applicable[0]
                    : this.modelscorestatus;

                  this.formInit(this.model);
                });
            } else {
              this.formInit(this.model);
            }
          });
        this.quest
          .viewAssessment({
            section_no: sec,
            email: this.app_email,
            criteria: criteria,
            type: 'applicant',
          })
          .subscribe(async (ele: any) => {
            if (ele.ass.length) {
              this.answer = ele.ass[0].assessment[0];
              this.questions = itemQues.sec;
              this.questions.map((items: any) => {
                if (this.answer.hasOwnProperty(items._id)) {
                  items.answer = this.answer[items._id];
                }
              });
              this.floating = true
              this.subsection = this.removeDuplicateObjectFromArray(
                this.questions,
                'sub_section_no'
              );
            } else {
              alert('No Data found');
            }
            this.spinner.hide();
          });
      });
  }

  onChangeControl(id: any, e: any, chk: any) {
    this.Sectionform.controls[id].setValue(chk == null ? e.target.value : e);
  }

  onChangeScore(id: any, e: any, chk: any) {
    if (e.target.value > 95) {
      this.modelscore[id] = 95
    } else if (e.target.value < 5) {
      this.modelscore[id] = 5
    } else
      this.modelscore[id] = chk == null ? Math.round(e.target.value / 5) * 5 : e;
  }
  isInputInvalid(subSectionNo: number): boolean {
    const score = this.modelscore[subSectionNo];
    var test = (score < 5 || score > 95) && score != null;
    this.checkminmax = test;
    return test;
  }

  removeDuplicateObjectFromArray(array: any, key: any) {
    return array.filter(
      (obj: any, index: any, self: any) =>
        index === self.findIndex((el: any) => el[key] === obj[key])
    );
  }
  checkModelScoreValidity(): boolean {
    for (const key in this.modelscore) {
      if (this.modelscore.hasOwnProperty(key)) {
        const score = this.modelscore[key];
        if ((score < 5 || score > 95) && (score != null)) {
          return false; // Invalid score found
        }
      }
    }
    return true; // All scores are valid
  }

  getNotApplicable(e: any, sub_section_no: any) {
    this.modelscorestatus[sub_section_no] = !e.target?.checked;
    this.modelscore[sub_section_no] = 'N/A';
  }

  onCopyValueClick(checked: any, item: any) {
    this.copyToggleChecked = checked
    if (checked) {
      for (let item_inner of item.questions) {
        const targetInput = document.getElementById('copyField_' + item_inner?._id) as HTMLInputElement;
        if (targetInput) {
          targetInput.value = item_inner.answer;
          this.modall[item_inner?._id] = item_inner.answer;
        }
      }
    }
    else {
      for (let item_inner of item.questions) {
        const targetInput = document.getElementById('copyField_' + item_inner?._id) as HTMLInputElement;
        if (targetInput) {
          targetInput.value = '';
          this.modall[item_inner?._id] = '';
        }
      }
    }

    this.Sectionform.patchValue(this.modall);
  }


}
