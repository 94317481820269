<app-header></app-header>
<app-sidenav></app-sidenav>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<div id="content" class="app-content" role="main">
  <div class="app-content-body ">
    <div class="main">
      <h4 class="hscore">Highest Score</h4>
    </div>

    <div class="col-sm-12">
      <div class="panel pd">
        <div class="row">
          <div class="col-sm-6">
            <p>Criteria</p>
            <select class="form-control" (change)="getSections($event)">
              <option value="">Select Criteria</option>
              <option value="Criteria 1">Criteria 1</option>
              <option value="Criteria 2 Large">Criteria 2 Large</option>
              <option value="Criteria 2 for SMB">Criteria 2 for SMB</option>
              <option value="Criteria 3 Large">Criteria 3 Large</option>
              <option value="Criteria 3 for SMB">Criteria 3 for SMB</option>
              <option value="Criteria 2A Large">Criteria 2A Large</option>
              <option value="Criteria 2A for SMB">Criteria 2A for SMB</option>
              <option value="Criteria 4">Criteria 4</option>
              <option value="Criteria 5">Criteria 5</option>
              <option value="Criteria 6">Criteria 6</option>
              <option value="Criteria 7">Criteria 7</option>
              <option value="Criteria 8">Criteria 8</option>
              <option value="Criteria 9">Criteria 9</option>
              <option value="Criteria 10">Criteria 10</option>
              <option value="Criteria 11">Criteria 11</option>
              <option value="Criteria 12">Criteria 12</option>
            </select>
          </div>
          <div class="col-sm-6">
            <p>Section</p>
            <select class="form-control" (change)="getSubSections($event)">
              <option value="">Select Subcriteria</option>
              <option value="" *ngFor="let item of this.section" value="{{ item._id.section_no }}">{{
                item._id.section_no }}</option>
            </select>
          </div>
        </div>
        <table class="table table-bordered" *ngIf="sec">
          <thead>
            <tr>
              <th class="text-center">Sub Section No</th>
              <th>Sub Section</th>
              <th>Highest Score </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let itemd of subSections; index as i">
              <td class="text-center">{{ itemd.sub_section_no }}</td>
              <td>{{ itemd.sub_section_title }}</td>
              <td><input type="number" class="form-control" [(ngModel)]="highestScrmodel[itemd.sub_section_no]" /></td>
            </tr>
          </tbody>
        </table>
        <button class="AddBtn" (click)="saveHighestScore()">Add</button>
      </div>
    </div>
  </div>
</div>