<app-header></app-header>
<app-sidenav></app-sidenav>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<div id="content" class="app-content" role="main">
  <div class="app-content-body ">
    <form [formGroup]="appForm">
      <div class="b-b-none wrapper-md main">
        <div class="row alignMid">

          <div class="col-sm-6 col-xs-12">
            <h4 class="m-n font-bold ">Letter of Intent</h4>
          </div>
          <div class="col-sm-6 text-right poppins hidden-xs pull-right">
          </div>
        </div>

      </div>

      <div class="wrapper-md" ng-controller="FormDemoCtrl">
        <div class="alert alert-info info-circle-alert">
          <i class="fa fa-info-circle"></i>
          This application form is a letter of intent and it is recommended that you should fill up the complete
          information correctly in order to facilitate the assessment team to undertake proper assessment of your
          prestigious unit.
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="panel panel-default">
              <div class="panel-heading"><b>Organization</b>
                <p class="labelInfo">Information about the organization</p>
              </div>
              <div class="panel-body">

                <div class="col-md-6 form-group">
                  <label>Organization Name </label>
                  <p class="labelInfo">Enter the full name of the Organization</p>
                  <input type="text" class="form-control " placeholder="" formControlName="organizationName"
                    value="{{ this.organizationNameVal }}">

                </div>

                <div class="col-md-6 form-group">
                  <label>Highest Ranking Official </label>
                  <p class="labelInfo">Enter the full name of the highest ranking official of the Organization</p>
                  <input type="text" class="form-control " placeholder="" formControlName="hightestRanking"
                    value="{{ this.hightestRankingVal }}">


                </div>

                <div class="col-md-6 form-group">
                  <label>Designation </label>
                  <p class="labelInfo">Enter the designation of the highest ranking official of the Organization</p>
                  <input type="text" class="form-control " placeholder="" formControlName="designation"
                    value="{{ this.designationVal }}">
                  <!-- <select class="form-control" formControlName="designation">
                    <option value="">Select Designation</option>
                    <option value="software Testing">software Testing</option>
                  </select> -->

                </div>

                <div class="col-md-6 form-group">
                  <label>Email </label>
                  <p class="labelInfo">Enter the email address of the highest ranking official of the Organization</p>
                  <input type="text" class="form-control email" placeholder="" formControlName="organizationEmail">
                  <div *ngIf="f.organizationEmail.errors && f.organizationEmail.errors.pattern && isValidFormSubmitted"
                    class="text-danger text-left mb-1">
                    <div *ngIf="f.organizationEmail.errors.pattern">Email is not valid</div>

                  </div>
                </div>



                <div class="col-md-6 form-group">
                  <label>Mobile </label>
                  <p class="labelInfo">Enter the mobile number of the highest ranking official of the Organization</p>
                  <div class="input-group">
                    <span class="input-group-addon">+91
                    </span>
                    <input type="text" class="form-control" placeholder="" formControlName="organizationMobile"
                      maxlength="10" minlength="10">

                  </div>
                  <div
                    *ngIf="f.organizationMobile.errors && f.organizationMobile.errors.pattern && isValidFormSubmitted"
                    class="text-danger text-left mb-1">
                    <div *ngIf="f.organizationMobile.errors.pattern">Please Enter 10 digit Mobile Number.</div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>



        <div class="row">
          <div class="col-sm-12">
            <div class="panel panel-default">
              <div class="panel-heading"><b>Applicant Information</b>
                <p class="labelInfo">Information about the Applicant’s Unit for the assessment</p>
              </div>
              <div class="panel-body">

                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>Name of Applicant Unit</label>
                      <p class="labelInfo">Enter the full name of the Unit for which assessment is to be undertaken</p>
                      <input type="text" class="form-control " placeholder="" formControlName="firstName">

                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>Applicant Unit is in operation from (DD/MM/YYYY)</label>
                      <p class="labelInfo">Select the date from which the proposed Unit for assessment is in operation
                      </p>

                      <div class="">
                        <input type="date" class="form-control" placeholder="DD/MM/YY" formControlName="operationForm">

                      </div>

                    </div>

                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>Highest Ranking Official</label>
                      <p class="labelInfo">Enter the full name of the highest ranking official of the proposed unit for
                        assessment</p>
                      <input type="text" class="form-control " placeholder=""
                        formControlName="applicanthightestRanking">

                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>Designation </label>
                      <p class="labelInfo">Enter the designation of the highest ranking official of the proposed unit
                        for assessment</p>
                      <input type="text" class="form-control" placeholder="" id=""
                        formControlName="contactDesignation" />

                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>Email </label>
                      <p class="labelInfo">Enter the email address of the highest ranking official of the proposed unit
                        for assessment</p>

                      <input type="text" class="form-control email" placeholder="" id="" formControlName="email" readonly />
                      <div *ngIf="f.email.errors && f.email.errors.pattern && isValidFormSubmitted"
                        class="text-danger text-left mb-1 ">
                        <div *ngIf="f.email.errors.pattern">Email is not valid</div>
                      </div>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label> Mobile </label>
                      <p class="labelInfo">Enter the mobile number of the highest ranking official of the proposed unit
                        for assessment</p>
                      <div class="input-group">
                        <span class="input-group-addon">+91
                        </span>
                        <input type="text" class="form-control" placeholder="" formControlName="mobileNo" maxlength="10"
                          minlength="10">

                      </div>
                      <div *ngIf="f.mobileNo.errors && f.mobileNo.errors.pattern && isValidFormSubmitted"
                        class="text-danger text-left mb-1 ">
                        <div *ngIf="f.mobileNo.errors.pattern">Please Enter 10 digit Mobile Number.</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>Address of the Applicant Unit </label>
                      <p class="labelInfo">Enter the complete address of the Unit for which assessment is to be
                        undertaken</p>
                      <textarea type="text" class="form-control app" placeholder="" id=""
                        formControlName="applicantAddress1"></textarea>

                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>Country </label>
                      <p class="labelInfo">Select the Country of the Applicant Unit</p>
                      <select class="form-control" formControlName="applicantCountry">
                        <option value=""> Select Country</option>
                        <option value="India">India</option>
                      </select>

                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label> Zip Code </label>
                      <p class="labelInfo">Enter 6 digit Zip Code </p>
                      <input type="" class="form-control " placeholder="" formControlName="applicantZipCode"
                        maxlength="6" minlength="6">

                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>State </label>
                      <p class="labelInfo">State of the Applicant Unit</p>
                      <!---<select class="form-control" formControlName="applicantState" (change)="getdistrictApplicant($event)">
                      <option value=""> Select State</option>
                      <option *ngFor="let obj of statedata" value={{obj}}> {{obj}} </option>
                    </select>
                    -->
                      <input type="" class="form-control" formControlName="applicantState"
                        value="{{ this.Applicant_state }}" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>City </label>
                      <p class="labelInfo">City of the Applicant Unit</p>
                      <!--- <select class="form-control" formControlName="applicantCity" >
                      <option> Select City</option>
                      <option *ngFor="let dobj of districtdataApplicant" value={{dobj}}> {{dobj}} </option>
                    </select>-->
                      <input type="" class="form-control" formControlName="applicantCity"
                        value="{{ this.Applicant_city }}" />
                    </div>
                  </div>

                </div>
                <div class="col-md-12">

                </div>

                <div class="row">

                  <div class="col-md-12" id="">

                    <div class="bTop"></div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-12">
                          <h4>Contact Person 1</h4>
                        </div>


                      </div>
                    </div>
                    <div class="col-md-6">

                      <div class="row">

                        <div class="col-md-12 form-group ">
                          <label>Contact Person </label>
                          <p class="labelInfo">Enter the full name of the Contact Person of the proposed unit for
                            assessment. All the communication for assessment shall be sent to the contact person
                            mentioned here.</p>

                          <input type="text" class="form-control" placeholder="" formControlName="contactPerson">

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Designation </label>
                          <p class="labelInfo">Enter the designation of the contact person of the proposed unit for
                            assessment</p>

                          <input type="text" class="form-control" placeholder="" id=""
                            formControlName="contactDesignation" />

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Email </label>
                          <p class="labelInfo">Enter the email address of the contact person of the proposed unit for
                            assessment</p>

                          <input type="text" class="form-control email" placeholder="" id=""
                            formControlName="contactEmail" />
                          <div *ngIf="f.contactEmail.errors && f.contactEmail.errors.pattern && isValidFormSubmitted"
                            class="text-danger text-left mb-1 ">
                            <div *ngIf="f.contactEmail.errors.pattern">Email is not valid</div>
                          </div>
                        </div>

                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label> Mobile </label>
                          <p class="labelInfo">Enter the mobile number of the contact person of the proposed unit for
                            assessment</p>
                          <div class="input-group">
                            <span class="input-group-addon">+91
                            </span>
                            <input type="text" class="form-control" placeholder="" formControlName="contactMobile"
                              maxlength="10" minlength="10">

                          </div>
                          <div *ngIf="f.contactMobile.errors && f.contactMobile.errors.pattern && isValidFormSubmitted"
                            class="text-danger text-left mb-1 ">
                            <div *ngIf="f.contactMobile.errors.pattern">Please Enter 10 digit Mobile Number.</div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-md-6">

                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Contact Address </label>
                          <p class="labelInfo">Enter the complete address of the Unit for which assessment is to be
                            undertaken</p>


                          <textarea class="form-control con1" placeholder="" id=""
                            formControlName="contactAddress1"></textarea>

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Country </label>
                          <p class="labelInfo">Select the Country of the Contact Person</p>
                          <select class="form-control" formControlName="contactCountry">
                            <option value=""> Select Country</option>
                            <option value="India">India</option>
                          </select>

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label> Zip Code </label>
                          <p class="labelInfo">Enter 6 digit Zip Code</p>
                          <input type="text" class="form-control " placeholder="" formControlName="contactZipCode"
                            maxlength="6" minlength="6">

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>State </label>
                          <p class="labelInfo">State of the Contact Person</p>
                          <input type="text" class="form-control" formControlName="contactState"
                            value="{{  this.con1_state }}" />
                          <!--<select class="form-control" formControlName="contactState" (change)="getdistrictApplicant($event)">
                          <option value=""> Select State</option>
                          <option *ngFor="let obj of statedata" value={{obj}}> {{obj}} </option>
                        </select>-->

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>City </label>
                          <p class="labelInfo">City of the Contact Person</p>
                          <input type="text" class="form-control" formControlName="contactCity"
                            value="{{  this.con1_city }}" />
                          <!--<select class="form-control" formControlName="contactCity">
                          <option> Select City</option>
                          <option *ngFor="let dobj of districtdataApplicant" value={{dobj}}> {{dobj}} </option>
                        </select>-->

                        </div>
                      </div>


                    </div>
                  </div>

                </div>

                <div class="row">

                  <div class="col-md-12" id="">

                    <div class="bTop"></div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-12">
                          <h4>Contact Person 2</h4>
                        </div>


                      </div>
                    </div>
                    <div class="col-md-6">

                      <div class="row">

                        <div class="col-md-12 form-group ">
                          <label>Contact Person </label>
                          <p class="labelInfo">Enter the full name of the Contact Person of the proposed unit for
                            assessment. All the communication for assessment shall be sent to the contact person
                            mentioned here.</p>

                          <input type="text" class="form-control" placeholder="" formControlName="contactPerson2">

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Designation </label>
                          <p class="labelInfo">Enter the designation of the contact person of the proposed unit for
                            assessment</p>

                          <input type="text" class="form-control" placeholder="" id=""
                            formControlName="contactDesignation2" />

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Email </label>
                          <p class="labelInfo">Enter the email address of the contact person of the proposed unit for
                            assessment</p>

                          <input type="text" class="form-control email" placeholder="" id=""
                            formControlName="contactEmail2" />
                          <div *ngIf="f.contactEmail2.errors && f.contactEmail2.errors.pattern && isValidFormSubmitted"
                            class="text-danger text-left mb-1 ">
                            <div *ngIf="f.contactEmail2.errors.pattern">Email is not valid</div>
                          </div>
                        </div>

                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label> Mobile </label>
                          <p class="labelInfo">Enter the mobile number of the contact person of the proposed unit for
                            assessment</p>
                          <div class="input-group">
                            <span class="input-group-addon">+91
                            </span>
                            <input type="text" class="form-control" placeholder="" formControlName="contactMobile2"
                              maxlength="10" minlength="10">

                          </div>
                          <div
                            *ngIf="f.contactMobile2.errors && f.contactMobile2.errors.pattern && isValidFormSubmitted"
                            class="text-danger text-left mb-1 ">
                            <div *ngIf="f.contactMobile2.errors.pattern">Please Enter 10 digit Mobile Number.</div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-md-6">

                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Contact Address </label>
                          <!-- &nbsp;<input class="form-check-input" type="checkbox" value="" id="defaultCheck1"> -->
                          <p class="labelInfo">Enter the complete address of the Unit for which assessment is to be
                            undertaken</p>


                          <textarea class="form-control con2" placeholder="" id=""
                            formControlName="contactAddress2"></textarea>

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Country </label>
                          <p class="labelInfo">Select the Country of the Contact Person</p>
                          <select class="form-control" formControlName="contactCountry2">
                            <option value=""> Select Country</option>
                            <option value="India">India</option>
                          </select>

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label> Zip Code </label>
                          <p class="labelInfo">Enter 6 digit Zip Code</p>
                          <input type="text" class="form-control " placeholder="" formControlName="contactZipCode2"
                            maxlength="6" minlength="6">

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>State </label>
                          <p class="labelInfo">State of the Contact Person</p>
                          <!-- <select class="form-control" formControlName="contactState2" (change)="getdistrictApplicant($event)">
                          <option value=""> Select State</option>
                          <option *ngFor="let obj of statedata" value={{obj}}> {{obj}} </option>
                        </select> -->
                          <input class="form-control" formControlName="contactState2" value="{{ this.con2_state }}" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>City </label>
                          <p class="labelInfo">City of the Contact Person</p>
                          <!-- <select class="form-control" formControlName="contactCity2">
                          <option> Select City</option>
                          <option *ngFor="let dobj of districtdataApplicant" value={{dobj}}> {{dobj}} </option>
                        </select>-->
                          <input class="form-control" formControlName="contactCity2" value="{{ this.con2_city }}" />
                        </div>
                      </div>



                    </div>
                  </div>

                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="bTop"></div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Sector </label>
                          <p class="labelInfo">Select the sector of operation of the proposed unit for assessment</p>

                          <select class="form-control" formControlName="sector" (change)="getProduct($event,'with')">
                            <option value="">Select Sector</option>
                            <option value="{{ sec }}" *ngFor="let sec of sector"> {{ sec }} </option>

                          </select>

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Food Category Name and Number </label>
                          <p class="labelInfo">Enter the food category name & number under which FSSAI license has been
                            issued </p>
                          <input type="text" class="form-control " placeholder=""
                            formControlName="FoodCategoryNameNumber">

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Investment in Plant & Machinery (for manufacturing unit) </label>
                          <p class="labelInfo">Enter the amount of investment in plant & machinery at the proposed unit
                            for assessment</p>
                          <input type="number" class="form-control " placeholder="" id=""
                            formControlName="applicantPlant" min="0" />

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-9 form-group">
                          <label>Annual Sales Turnover (Organization) </label>
                          <p class="labelInfo">Enter the annual sales turnover of the Organization of the previous
                            financial year (FY 2020-21)</p>
                          <input type="text" class="form-control " placeholder="" id=""
                            formControlName="annualOrganization" />

                        </div>
                        <div class="col-md-3 form-group">
                          <label>Currency </label>
                          <select class="form-control annualOrganizationRupee"
                            formControlName="annualOrganizationRupee">
                            <option value="INR">INR</option>
                            <option value="USD">USD</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Classification </label>
                          <p class="labelInfo">Select the classification of the Organization</p>
                          <div class="info-select-box">
                            <select class="form-control" formControlName="classification">
                              <option value="">Select</option>
                              <option value="Micro">Micro</option>
                              <option value="Small">Small</option>
                              <option value="Medium">Medium</option>
                              <option value="Large">Large</option>
                            </select>
                          </div>

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Criteria of Assessment of the Applicant Unit </label>
                          <p class="labelInfo">For purposes of assigning categorization to Award Applications (e.g.
                            large, medium, small and micro)

                            Refer info section for Enterprise /Unit
                          </p>
                          <ul>
                            <li class="labelInfo"> Proof of the latest investment figure (either a registration
                              certificate as per MSME Act / UAM or a certification by the auditors/authority) is to be
                              submitted</li>
                            <li class="labelInfo"> Proof of the latest investment figure (either a registration
                              certificate as per MSME Act / UAM or a certification by the auditors/authority) is to be
                              submitted</li>
                          </ul>



                          <div class="info-select-box">
                            <select class="form-control" formControlName="criteria">
                              <option value="">Select Criteria</option>
                              <option value="Criteria 1">Criteria 1</option>
                              <option value="Criteria 2 Large">Criteria 2 Large</option>
                              <option value="Criteria 2 for SMB">Criteria 2 for SMB</option>
                              <option value="Criteria 3 Large">Criteria 3 Large</option>
                              <option value="Criteria 3 for SMB">Criteria 3 for SMB</option>
                              <option value="Criteria 4">Criteria 4</option>
                              <option value="Criteria 5">Criteria 5</option>
                              <option value="Criteria 6">Criteria 6</option>
                              <option value="Criteria 7">Criteria 7</option>
                              <option value="Criteria 8">Criteria 8</option>
                              <option value="Criteria 9">Criteria 9</option>
                              <option value="Criteria 10">Criteria 10</option>
                              <option value="Criteria 11">Criteria 11</option>
                              <option value="Criteria 12">Criteria 12</option>
                            </select>
                            <i class="fa fa-info-circle" data-toggle="modal" data-target="#exampleModalLong"
                              aria-hidden="true"></i>
                          </div>



                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Product & Services </label>
                          <p class="labelInfo">Select the product/services offered by the proposed unit for assessment
                          </p>
                          <select class="form-control" formControlName="product">
                            <option value="">Select Product</option>
                            <option value="{{ pro }}" *ngFor="let pro of products"> {{ pro }}</option>

                          </select>

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Employee </label>
                          <p class="labelInfo">Enter the total number of employees at the proposed unit for assessment
                          </p>
                          <input type="number" class="form-control " placeholder="" formControlName="totalEmployee"
                            min="0">

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Investment in Equipment (for service unit) </label>
                          <p class="labelInfo">Enter the amount of investment in equipments at the proposed unit for
                            assessment</p>
                          <input type="number" class="form-control" placeholder="" id=""
                            formControlName="applicantEquipment" min="0" />

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-9 form-group">
                          <label>Annual Sales Turnover (proposed Applicant Unit)</label>
                          <p class="labelInfo">Enter the annual sales turnover of the proposed applicant unit for
                            assessment of the previous financial year (FY 2020-21)</p>
                          <input type="text" class="form-control " placeholder="" id=""
                            formControlName="annualApplicant" />

                        </div>
                        <div class="col-md-3 form-group">
                          <label>Currency </label>

                          <select class="form-control annualApplicantRupee" formControlName="annualApplicantRupee">
                            <option value="INR" selected>INR</option>
                            <option value="USD">USD</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Form 14A</label>

                          <img [src]="this.uploadedimage" style="width:30%" />
                        </div>
                      </div>
                    </div>


                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>



        <div style="text-align: right; margin:10px 0px;">

        </div>

      </div>
    </form>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center" id="exampleModalLongTitle">Criteria Table, 2024</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Nature of Business </th>
              <th scope="col">Large Organisations </th>
              <th scope="col">Medium Organisations </th>
              <th scope="col">Small Organisations</th>
              <th scope="col">Basis of Criteria </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="col">Food Vendors (Mobile Carts and Stalls, Meat Shops, Sweet Meat Shops, Confectioneriees,
                Vending and Dispensing.)</td>
              <td scope="col">Criteria 1 </td>
              <td scope="col">Criteria 1 </td>
              <td scope="col">Criteria 1 </td>
              <td scope="col">Based on FSSAI Checkpoints and CII 14 Points Check </td>
            </tr>

            <tr>
              <td scope="col">Food Service : Hotels & Eateries (Involved in Preparation, Serving, Take Aways with
                Permanent Establishment and Address) e.g: Restaurants, Catering, Institutional Canteens, Cloud Kichen,
                Food Courts)</td>
              <td scope="col">Criteria 2 Large </td>
              <td scope="col">Criteria 2 for SMB </td>
              <td scope="col">Criteria 2 for SMB </td>
              <td scope="col">Based on ISO 22000, FSSR and PRP for Food Service Establishment Having permanent addresses
                + Change Management Initiatives + Social Compilance </td>
            </tr>

            <tr>
              <td scope="col">Manufacturing (Food Processing Companies)</td>
              <td scope="col">Criteria 3 Large </td>
              <td scope="col">Criteria 3 for SMB </td>
              <td scope="col">Criteria 3 for SMB </td>
              <td scope="col">Based on ISO 22000: 2018 ISO 22002-1 + FSSR, Change Management Initiatives + Social
                Compliance</td>
            </tr>

            <tr>
              <td scope="col">Food Service: Food Wholesale, Retail, Warehousing, Transportation, C&F</td>
              <td scope="col">Criteria 4 </td>
              <td scope="col">Criteria 4</td>
              <td scope="col">Criteria 4 </td>
              <td scope="col">Based on FSSR + Risk Analysis + Social Compliance+ Change Management Initiatives</td>
            </tr>

            <tr>
              <td scope="col">Primary Production: Fruits and Vegetables Fresh Produce (On farm practices, Grading.
                Packing, Dispatch)</td>
              <td scope="col">Criteria 5 </td>
              <td scope="col">Criteria 5</td>
              <td scope="col">Criteria 5 </td>
              <td scope="col">Based on Global Gap, Codex Standards + Social and Regulatory Compliance</td>
            </tr>

            <tr>
              <td scope="col">Packaging & Food Chain Inputs Criteria 6 Providers (Manufacturing of Cleaning &
                Sanitation, Food Equipment, Pest Control)</td>
              <td scope="col">Criteria 6 </td>
              <td scope="col">Criteria 6</td>
              <td scope="col">Criteria 6 </td>
              <td scope="col">Based on ISO 22000; 2018 & Best Practices in PRP&GMP Change Management Initiatives +
                Social & Regulatory Compliance </td>
            </tr>

            <tr>
              <td scope="col">Joint Application of Customer & Supplier / Co-Packers / Franchise 3rd Party - Food Safety
                Excellence through Partnership Development</td>
              <td scope="col">Criteria 7 </td>
              <td scope="col">Criteria 7</td>
              <td scope="col">Criteria 7 </td>
              <td scope="col">Best Practices in Partnership Development Criteria 3 or Criteria 2 as applicable</td>
            </tr>

            <tr>
              <td scope="col">Primary Production: Dairy Farm</td>
              <td scope="col">Criteria 8 </td>
              <td scope="col">Criteria 8</td>
              <td scope="col">Criteria 8 </td>
              <td scope="col">Based on GLOBAL GAP + Social Compliance </td>
            </tr>

            <tr>
              <td scope="col">E-commerce- online Marketplace (Grocery. fruits and Vegetables & products)</td>
              <td scope="col">Criteria 9 </td>
              <td scope="col">Criteria 9</td>
              <td scope="col">Criteria 9 </td>
              <td scope="col">Based on FSSAI checkpoints for online food delivery + ISO 22000:2018 + FSSR for retail &
                distribution</td>
            </tr>
            <tr>
              <td scope="col">E-commerce-Ready to eat cooked Meal </td>
              <td scope="col">Criteria 10 </td>
              <td scope="col">Criteria 10</td>
              <td scope="col">Criteria 10 </td>
              <td scope="col">Based on FSSAI checkpoints for catering and online food delivery + ISO 22000:2018</td>
            </tr>

            <tr>
              <td scope="col">Laboratories Performance of Food Analysis & Testing Labs </td>
              <td scope="col">Criteria 11 </td>
              <td scope="col">Criteria 11</td>
              <td scope="col">Criteria 11 </td>
              <td scope="col">Based on ISO 17025 2017, Best Practices & Leadership Initiatives+ Statutory & Social
                Compliance</td>
            </tr>

            <tr>
              <td scope="col">Innovation Management with Food Safety</td>
              <td scope="col">Criteria 12 </td>
              <td scope="col">Criteria 12</td>
              <td scope="col">Criteria 12 </td>
              <td scope="col">Innovation in Best Practices in FSMS</td>
            </tr>

          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>

      </div>
    </div>
  </div>
</div>

<script>
  document.getElementById("newsectionbtn").onclick = function () {
    var container = document.getElementById("container");
    var section = document.getElementById("mainsection");
    container.appendChild(section.cloneNode(true));
  }
</script>