import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicantService } from 'src/app/services/applicant.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationService } from 'src/app/services/location.service';
import { CKEditor4 } from 'ckeditor4-angular/ckeditor';
import { TosterService } from 'src/app/services/toster.service';
import { noWhitespaceValidator } from 'src/shared/whitespace.validator';


import Swal from 'sweetalert2';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css'],
  animations: [
    trigger('formfade', [
      state('inactive', style({
        opacity: 0
      })),
      state('active', style({
        opacity: 1
      })),

      transition('inactive => active', [
        animate(200)
      ])
    ])


  ]
})
export class IntroComponent implements OnInit {
  appForm!: FormGroup;
  udata: any;
  email: any;
  applicantSdata: any;
  _id: any;
  statedata: any;
  statedataBill: any[] = [];
  submitbutton: boolean = false;
  stepCounter = 0;
  stepArray = [true, false, false, false, false, false, false, false, false];
  stepsDone = [];
  btnStatusDisabled: boolean = false;

  //Brijesh
  productsServices: string = '';
  constructor(private toast: TosterService, private locationS: LocationService, private applicantS: ApplicantService, private spinner: NgxSpinnerService, private router: Router) { }

  ngOnInit(): void {

    this.udata = localStorage.getItem('userdata');
    this.udata = JSON.parse(this.udata);

    // if (this.udata.email == 'rajesh.kopparapu@varuna.net'){
    //   window.location.href = '/joint-applicant-varuna';
    // }
    if (this.udata.email == 'suresh.garg@zeon.co.in') {
      window.location.href = '/joint-applicant-zeon';
    }
    // if (this.udata.email == 'negi@tirupatilifesciences.com') {
    //   window.location.href = '/joint-applicant-tirupati';
    // }
    if (this.udata.email == 'info@aicofoods.com') {
      window.location.href = '/joint-applicant-aico';
    }


    if (this.udata.role == 'applicant') {
      this.email = this.udata.email;

      // this.getData();
      // this.getStateByCountryName('India', '');
      // this.getStateByCountryNameBill('India', '')

      this.getPromise();




    }


    this.appForm = new FormGroup({

      email: new FormControl(''),
      id: new FormControl(''),

      organizationProfile: new FormGroup({
        locationName: new FormControl(['', [noWhitespaceValidator]]),
        locationAddress: new FormControl(['', [noWhitespaceValidator]]),
        locationCity: new FormControl(['', [noWhitespaceValidator]]),
        locationState: new FormControl(['', [noWhitespaceValidator]]),
        locationZip: new FormControl(['', [noWhitespaceValidator]]),
        scopeAward: new FormControl(['', [noWhitespaceValidator]]),
        locationHeadName: new FormControl(['', [noWhitespaceValidator]]),
        locationHeadEmail: new FormControl(['', [noWhitespaceValidator]]),
        locationHeadMobile: new FormControl(['', [noWhitespaceValidator]]),
        locationContactName: new FormControl(['', [noWhitespaceValidator]]),
        locationContactEmail: new FormControl(['', [noWhitespaceValidator]]),
        locationContactMobile: new FormControl(['', [noWhitespaceValidator]]),
        productsServices: new FormControl(['', [noWhitespaceValidator]]),
        customers: new FormControl(['', [noWhitespaceValidator]]),
        suppliers: new FormControl(['', [noWhitespaceValidator]]),
        annualSales: new FormControl(['', [noWhitespaceValidator]]),
        distributors: new FormControl(['', [noWhitespaceValidator]]),
        competitors: new FormControl(['', [noWhitespaceValidator]]),
        people: new FormControl(['', [noWhitespaceValidator]]),
        orgStructure: new FormControl(['', [noWhitespaceValidator]]),
        collaborations: new FormControl(['', [noWhitespaceValidator]]),
        certifications: new FormControl(['', [noWhitespaceValidator]]),
        technologies: new FormControl(['', [noWhitespaceValidator]]),
        keyChallenges: new FormControl(['', [noWhitespaceValidator]]),
        itFacilities: new FormControl(['', [noWhitespaceValidator]]),
        overcome: new FormControl(['', [noWhitespaceValidator]]),
        processFlow: new FormControl(['', [noWhitespaceValidator]]),
      }),

      keyFoodSafety: new FormGroup({
        incidents1920: new FormControl(['', [noWhitespaceValidator]]),
        incidents2021: new FormControl(['', [noWhitespaceValidator]]),
        incidents2122: new FormControl(['', [noWhitespaceValidator]]),
        inspection1920: new FormControl(['', [noWhitespaceValidator]]),
        inspection2021: new FormControl(['', [noWhitespaceValidator]]),
        inspection2122: new FormControl(['', [noWhitespaceValidator]]),
        ncr1920: new FormControl(['', [noWhitespaceValidator]]),
        ncr2021: new FormControl(['', [noWhitespaceValidator]]),
        ncr2122: new FormControl(['', [noWhitespaceValidator]]),
      }),

      organizationPerformance: new FormGroup({
        revenue1920: new FormControl(['', [noWhitespaceValidator]]),
        revenue2021: new FormControl(['', [noWhitespaceValidator]]),
        revenue2122: new FormControl(['', [noWhitespaceValidator]]),
        inventory1920: new FormControl(['', [noWhitespaceValidator]]),
        inventory2021: new FormControl(['', [noWhitespaceValidator]]),
        inventory2122: new FormControl(['', [noWhitespaceValidator]]),
        products1920: new FormControl(['', [noWhitespaceValidator]]),
        products2021: new FormControl(['', [noWhitespaceValidator]]),
        products2122: new FormControl(['', [noWhitespaceValidator]]),
        other1920: new FormControl(['', [noWhitespaceValidator]]),
        other2021: new FormControl(['', [noWhitespaceValidator]]),
        other2122: new FormControl(['', [noWhitespaceValidator]]),


      }),

      trendResults: new FormGroup({
        a_1920: new FormControl(['', [noWhitespaceValidator]]),
        a_2021: new FormControl(['', [noWhitespaceValidator]]),
        a_2122: new FormControl(['', [noWhitespaceValidator]]),
        b_1920: new FormControl(['', [noWhitespaceValidator]]),
        b_2021: new FormControl(['', [noWhitespaceValidator]]),
        b_2122: new FormControl(['', [noWhitespaceValidator]]),
        c_1920: new FormControl(['', [noWhitespaceValidator]]),
        c_2021: new FormControl(['', [noWhitespaceValidator]]),
        c_2122: new FormControl(['', [noWhitespaceValidator]]),
        d_1920: new FormControl(['', [noWhitespaceValidator]]),
        d_2021: new FormControl(['', [noWhitespaceValidator]]),
        d_2122: new FormControl(['', [noWhitespaceValidator]]),
        e_1920: new FormControl(['', [noWhitespaceValidator]]),
        e_2021: new FormControl(['', [noWhitespaceValidator]]),
        e_2122: new FormControl(['', [noWhitespaceValidator]]),
        f_1920: new FormControl(['', [noWhitespaceValidator]]),
        f_2021: new FormControl(['', [noWhitespaceValidator]]),
        f_2122: new FormControl(['', [noWhitespaceValidator]]),
        g_1920: new FormControl(['', [noWhitespaceValidator]]),
        g_2021: new FormControl(['', [noWhitespaceValidator]]),
        g_2122: new FormControl(['', [noWhitespaceValidator]]),
        h_1920: new FormControl(['', [noWhitespaceValidator]]),
        h_2021: new FormControl(['', [noWhitespaceValidator]]),
        h_2122: new FormControl(['', [noWhitespaceValidator]]),
        i_1920: new FormControl(['', [noWhitespaceValidator]]),
        i_2021: new FormControl(['', [noWhitespaceValidator]]),
        i_2122: new FormControl(['', [noWhitespaceValidator]]),
        j_1920: new FormControl(['', [noWhitespaceValidator]]),
        j_2021: new FormControl(['', [noWhitespaceValidator]]),
        j_2122: new FormControl(['', [noWhitespaceValidator]]),
        k_1920: new FormControl(['', [noWhitespaceValidator]]),
        k_2021: new FormControl(['', [noWhitespaceValidator]]),
        k_2122: new FormControl(['', [noWhitespaceValidator]]),
        l_1920: new FormControl(['', [noWhitespaceValidator]]),
        l_2021: new FormControl(['', [noWhitespaceValidator]]),
        l_2122: new FormControl(['', [noWhitespaceValidator]]),
        m_1920: new FormControl(['', [noWhitespaceValidator]]),
        m_2021: new FormControl(['', [noWhitespaceValidator]]),
        m_2122: new FormControl(['', [noWhitespaceValidator]]),
        n_1920: new FormControl(['', [noWhitespaceValidator]]),
        n_2021: new FormControl(['', [noWhitespaceValidator]]),
        n_2122: new FormControl(['', [noWhitespaceValidator]]),
        o_1920: new FormControl(['', [noWhitespaceValidator]]),
        o_2021: new FormControl(['', [noWhitespaceValidator]]),
        o_2122: new FormControl(['', [noWhitespaceValidator]]),
        p_1920: new FormControl(['', [noWhitespaceValidator]]),
        p_2021: new FormControl(['', [noWhitespaceValidator]]),
        p_2122: new FormControl(['', [noWhitespaceValidator]]),
        q_1920: new FormControl(['', [noWhitespaceValidator]]),
        q_2021: new FormControl(['', [noWhitespaceValidator]]),
        q_2122: new FormControl(['', [noWhitespaceValidator]]),
        r_1920: new FormControl(['', [noWhitespaceValidator]]),
        r_2021: new FormControl(['', [noWhitespaceValidator]]),
        r_2122: new FormControl(['', [noWhitespaceValidator]]),
        s_1920: new FormControl(['', [noWhitespaceValidator]]),
        s_2021: new FormControl(['', [noWhitespaceValidator]]),
        s_2122: new FormControl(['', [noWhitespaceValidator]]),
        t_1920: new FormControl(['', [noWhitespaceValidator]]),
        t_2021: new FormControl(['', [noWhitespaceValidator]]),
        t_2122: new FormControl(['', [noWhitespaceValidator]]),
        u_1920: new FormControl(['', [noWhitespaceValidator]]),
        u_2021: new FormControl(['', [noWhitespaceValidator]]),
        u_2122: new FormControl(['', [noWhitespaceValidator]]),
        v_1920: new FormControl(['', [noWhitespaceValidator]]),
        v_2021: new FormControl(['', [noWhitespaceValidator]]),
        v_2122: new FormControl(['', [noWhitespaceValidator]]),
      }),


      sectorPractices: new FormGroup({
        a_1: new FormControl(['', [noWhitespaceValidator]]),
        a_2: new FormControl(['', [noWhitespaceValidator]]),
        a_3: new FormControl(['', [noWhitespaceValidator]]),
        a_4: new FormControl(['', [noWhitespaceValidator]]),
        b_1: new FormControl(['', [noWhitespaceValidator]]),
        b_2: new FormControl(['', [noWhitespaceValidator]]),
        b_3: new FormControl(['', [noWhitespaceValidator]]),
        b_4: new FormControl(['', [noWhitespaceValidator]]),
        c_1: new FormControl(['', [noWhitespaceValidator]]),
        c_2: new FormControl(['', [noWhitespaceValidator]]),
        c_3: new FormControl(['', [noWhitespaceValidator]]),
        c_4: new FormControl(['', [noWhitespaceValidator]]),
        d_1: new FormControl(['', [noWhitespaceValidator]]),
        d_2: new FormControl(['', [noWhitespaceValidator]]),
        d_3: new FormControl(['', [noWhitespaceValidator]]),
        d_4: new FormControl(['', [noWhitespaceValidator]]),
        e_1: new FormControl(['', [noWhitespaceValidator]]),
        e_2: new FormControl(['', [noWhitespaceValidator]]),
        e_3: new FormControl(['', [noWhitespaceValidator]]),
        e_4: new FormControl(['', [noWhitespaceValidator]]),
        f_1: new FormControl(['', [noWhitespaceValidator]]),
        f_2: new FormControl(['', [noWhitespaceValidator]]),
        f_3: new FormControl(['', [noWhitespaceValidator]]),
        f_4: new FormControl(['', [noWhitespaceValidator]]),
        g_1: new FormControl(['', [noWhitespaceValidator]]),
        g_2: new FormControl(['', [noWhitespaceValidator]]),
        g_3: new FormControl(['', [noWhitespaceValidator]]),
        g_4: new FormControl(['', [noWhitespaceValidator]]),
        h_1: new FormControl(['', [noWhitespaceValidator]]),
        h_2: new FormControl(['', [noWhitespaceValidator]]),
        h_3: new FormControl(['', [noWhitespaceValidator]]),
        h_4: new FormControl(['', [noWhitespaceValidator]]),
        i_1: new FormControl(['', [noWhitespaceValidator]]),
        i_2: new FormControl(['', [noWhitespaceValidator]]),
        i_3: new FormControl(['', [noWhitespaceValidator]]),
        i_4: new FormControl(['', [noWhitespaceValidator]]),
        j_1: new FormControl(['', [noWhitespaceValidator]]),
        j_2: new FormControl(['', [noWhitespaceValidator]]),
        j_3: new FormControl(['', [noWhitespaceValidator]]),
        j_4: new FormControl(['', [noWhitespaceValidator]]),
        k_1: new FormControl(['', [noWhitespaceValidator]]),
        k_2: new FormControl(['', [noWhitespaceValidator]]),
        k_3: new FormControl(['', [noWhitespaceValidator]]),
        k_4: new FormControl(['', [noWhitespaceValidator]]),
      }),



      envImpact: new FormGroup({
        a_1: new FormControl(['', [noWhitespaceValidator]]),
        a_2: new FormControl(['', [noWhitespaceValidator]]),
        a_3: new FormControl(['', [noWhitespaceValidator]]),
        b_1: new FormControl(['', [noWhitespaceValidator]]),
        b_2: new FormControl(['', [noWhitespaceValidator]]),
        b_3: new FormControl(['', [noWhitespaceValidator]]),
        c_1: new FormControl(['', [noWhitespaceValidator]]),
        c_2: new FormControl(['', [noWhitespaceValidator]]),
        c_3: new FormControl(['', [noWhitespaceValidator]]),
        d_1: new FormControl(['', [noWhitespaceValidator]]),
        d_2: new FormControl(['', [noWhitespaceValidator]]),
        d_3: new FormControl(['', [noWhitespaceValidator]]),
        e_1: new FormControl(['', [noWhitespaceValidator]]),
        e_2: new FormControl(['', [noWhitespaceValidator]]),
        e_3: new FormControl(['', [noWhitespaceValidator]]),
        f_1: new FormControl(['', [noWhitespaceValidator]]),
        f_2: new FormControl(['', [noWhitespaceValidator]]),
        f_3: new FormControl(['', [noWhitespaceValidator]]),
      }),

      checkpoints: new FormGroup({
        a_1: new FormControl(['', [noWhitespaceValidator]]),
        b_1: new FormControl(['', [noWhitespaceValidator]]),
        c_1: new FormControl(['', [noWhitespaceValidator]]),
        d_1: new FormControl(['', [noWhitespaceValidator]]),
        e_1: new FormControl(['', [noWhitespaceValidator]]),
        f_1: new FormControl(['', [noWhitespaceValidator]]),
        g_1: new FormControl(['', [noWhitespaceValidator]]),
        h_1: new FormControl(['', [noWhitespaceValidator]]),
        i_1: new FormControl(['', [noWhitespaceValidator]]),
        j_1: new FormControl(['', [noWhitespaceValidator]]),
        k_1: new FormControl(['', [noWhitespaceValidator]]),
        l_1: new FormControl(['', [noWhitespaceValidator]]),
        m_1: new FormControl(['', [noWhitespaceValidator]]),
        n_1: new FormControl(['', [noWhitespaceValidator]]),
        o_1: new FormControl(['', [noWhitespaceValidator]]),
        p_1: new FormControl(['', [noWhitespaceValidator]]),
        q_1: new FormControl(['', [noWhitespaceValidator]]),

      }),

      foodImprovements: new FormGroup({
        a_1: new FormControl(['', [noWhitespaceValidator]]),
        a_2: new FormControl(['', [noWhitespaceValidator]]),
        a_3: new FormControl(['', [noWhitespaceValidator]]),
        a_4: new FormControl(['', [noWhitespaceValidator]]),
        b_1: new FormControl(['', [noWhitespaceValidator]]),
        b_2: new FormControl(['', [noWhitespaceValidator]]),
        b_3: new FormControl(['', [noWhitespaceValidator]]),
        b_4: new FormControl(['', [noWhitespaceValidator]]),
        c_1: new FormControl(['', [noWhitespaceValidator]]),
        c_2: new FormControl(['', [noWhitespaceValidator]]),
        c_3: new FormControl(['', [noWhitespaceValidator]]),
        c_4: new FormControl(['', [noWhitespaceValidator]]),

        d_1: new FormControl(['', [noWhitespaceValidator]]),
        d_2: new FormControl(['', [noWhitespaceValidator]]),
        d_3: new FormControl(['', [noWhitespaceValidator]]),
        d_4: new FormControl(['', [noWhitespaceValidator]]),
        e_1: new FormControl(['', [noWhitespaceValidator]]),
        e_2: new FormControl(['', [noWhitespaceValidator]]),
        e_3: new FormControl(['', [noWhitespaceValidator]]),
        e_4: new FormControl(['', [noWhitespaceValidator]]),
        f_1: new FormControl(['', [noWhitespaceValidator]]),
        f_2: new FormControl(['', [noWhitespaceValidator]]),
        f_3: new FormControl(['', [noWhitespaceValidator]]),
        f_4: new FormControl(['', [noWhitespaceValidator]]),
        g_1: new FormControl(['', [noWhitespaceValidator]]),
        g_2: new FormControl(['', [noWhitespaceValidator]]),
        g_3: new FormControl(['', [noWhitespaceValidator]]),
        g_4: new FormControl(['', [noWhitespaceValidator]]),
        h_1: new FormControl(['', [noWhitespaceValidator]]),
        h_2: new FormControl(['', [noWhitespaceValidator]]),
        h_3: new FormControl(['', [noWhitespaceValidator]]),
        h_4: new FormControl(['', [noWhitespaceValidator]]),
        i_1: new FormControl(['', [noWhitespaceValidator]]),
        i_2: new FormControl(['', [noWhitespaceValidator]]),
        i_3: new FormControl(['', [noWhitespaceValidator]]),
        i_4: new FormControl(['', [noWhitespaceValidator]]),
        j_1: new FormControl(['', [noWhitespaceValidator]]),
        j_2: new FormControl(['', [noWhitespaceValidator]]),
        j_3: new FormControl(['', [noWhitespaceValidator]]),
        j_4: new FormControl(['', [noWhitespaceValidator]]),
        k_1: new FormControl(['', [noWhitespaceValidator]]),
        k_2: new FormControl(['', [noWhitespaceValidator]]),
        k_3: new FormControl(['', [noWhitespaceValidator]]),
        k_4: new FormControl(['', [noWhitespaceValidator]]),

      }),


      otherSpecific: new FormGroup({
        primaryChemical: new FormControl(['', [noWhitespaceValidator]]),
        waterTreatment: new FormControl(['', [noWhitespaceValidator]]),
        ingredients: new FormControl(['', [noWhitespaceValidator]]),
        regultions: new FormControl(['', [noWhitespaceValidator]]),
      }),

    });




  }


  getData(): void {
    this.spinner.show();
    this.applicantS
      .GetAdminIntroSinglebyemail(this.email, this.udata._id)
      .subscribe((data: any) => {

        // this.getStateByCountryName('India', '');
        // this.getStateByCountryNameBill(
        //   'India',
        //   ''
        // );
        // applicantSdata.organizationProfile?.[0].productsServices

        this._id = data.applicantData?._id;
        this.applicantSdata = (data.applicantData.organizationProfile.length > 0) ? data.applicantData : {
          "_id": "",
          "email": "",
          "__v": 0,
          "checkpoints": [
            {
              "a_1": "",
              "b_1": "",
              "c_1": "",
              "d_1": "",
              "e_1": "",
              "f_1": "",
              "g_1": "",
              "h_1": "",
              "i_1": "",
              "j_1": "",
              "k_1": "",
              "l_1": "",
              "m_1": "",
              "n_1": "",
              "o_1": "",
              "p_1": "",
              "q_1": "",
              "r_1": "",

            }
          ],
          "created_at": "2023-03-20T06:04:29.574Z",
          "envImpact": [
            {
              "a_1": "",
              "a_2": "",
              "a_3": "",
              "a_4": "",
              "b_1": "",
              "b_2": "",
              "b_3": "",
              "b_4": "",
              "c_1": "",
              "c_2": "",
              "c_3": "",
              "c_4": "",
              "d_1": "",
              "d_2": "",
              "d_3": "",
              "d_4": "",
              "e_1": "",
              "e_2": "",
              "e_3": "",
              "e_4": "",
              "f_1": "",
              "f_2": "",
              "f_3": "",
              "f_4": "",
              "g_1": "",
              "g_2": "",
              "g_3": "",
              "g_4": "",
              "h_1": "",
              "h_2": "",
              "h_3": "",
              "h_4": "",
              "i_1": "",
              "i_2": "",
              "i_3": "",
              "i_4": "",
              "j_1": "",
              "j_2": "",
              "j_3": "",
              "j_4": "",
              "k_1": "",
              "k_2": "",
              "k_3": "",
              "k_4": "",

            }
          ],
          "foodImprovements": [
            {
              "a_1": "",
              "a_2": "",
              "a_3": "",
              "a_4": "",
              "b_1": "",
              "b_2": "",
              "b_3": "",
              "b_4": "",
              "c_1": "",
              "c_2": "",
              "c_3": "",
              "c_4": "",
              "d_1": "",
              "d_2": "",
              "d_3": "",
              "d_4": "",
              "e_1": "",
              "e_2": "",
              "e_3": "",
              "e_4": "",
              "f_1": "",
              "f_2": "",
              "f_3": "",
              "f_4": "",
              "g_1": "",
              "g_2": "",
              "g_3": "",
              "g_4": "",
              "h_1": "",
              "h_2": "",
              "h_3": "",
              "h_4": "",
              "i_1": "",
              "i_2": "",
              "i_3": "",
              "i_4": "",
              "j_1": "",
              "j_2": "",
              "j_3": "",
              "j_4": "",
              "k_1": "",
              "k_2": "",
              "k_3": "",
              "k_4": "",

            }
          ],

          "keyFoodSafety": [
            {
              "incidents1920": "",
              "incidents2021": "",
              "incidents2122": "",
              "inspection1920": "",
              "inspection2021": "",
              "inspection2122": "",
              "ncr1920": "",
              "ncr2021": "",
              "ncr2122": "",

            }
          ],
          "organizationPerformance": [
            {
              "revenue1920": "",
              "revenue2021": "",
              "revenue2122": "",
              "inventory1920": "",
              "inventory2021": "",
              "inventory2122": "",
              "products1920": "",
              "products2021": "",
              "products2122": "",
              "other1920": "",
              "other2021": "",
              "other2122": "",

            }
          ],
          "organizationProfile": [
            {
              "locationName": "",
              "locationAddress": "",
              "locationCity": "",
              "locationState": "",
              "locationZip": "",
              "scopeAward": "",
              "locationHeadName": "",
              "locationHeadEmail": "",
              "locationHeadMobile": "",
              "locationContactName": "",
              "locationContactEmail": "",
              "locationContactMobile": "",
              "productsServices": "",
              "customers": "",
              "suppliers": "",
              "annualSales": "",
              "distributors": "",
              "competitors": "",
              "people": "",
              "orgStructure": "",
              "collaborations": "",
              "certifications": "",
              "technologies": "",
              "keyChallenges": "",
              "itFacilities": "",
              "overcome": "",
              "processFlow": "",

            }
          ],
          "otherSpecific": [
            {
              "primaryChemical": "",
              "waterTreatment": "",
              "ingredients": "",
              "regultions": "",

            }
          ],
          "sectorPractices": [
            {
              "a_1": "",
              "a_2": "",
              "a_3": "",
              "a_4": "",
              "b_1": "",
              "b_2": "",
              "b_3": "",
              "b_4": "",
              "c_1": "",
              "c_2": "",
              "c_3": "",
              "c_4": "",
              "d_1": "",
              "d_2": "",
              "d_3": "",
              "d_4": "",
              "e_1": "",
              "e_2": "",
              "e_3": "",
              "e_4": "",
              "f_1": "",
              "f_2": "",
              "f_3": "",
              "f_4": "",
              "g_1": "",
              "g_2": "",
              "g_3": "",
              "g_4": "",
              "h_1": "",
              "h_2": "",
              "h_3": "",
              "h_4": "",
              "i_1": "",
              "i_2": "",
              "i_3": "",
              "i_4": "",
              "j_1": "",
              "j_2": "",
              "j_3": "",
              "j_4": "",
              "k_1": "",
              "k_2": "",
              "k_3": "",
              "k_4": "",

            }
          ],
          "trendResults": [
            {
              "a_1920": "",
              "a_2021": "",
              "a_2122": "",
              "b_1920": "",
              "b_2021": "",
              "b_2122": "",
              "c_1920": "",
              "c_2021": "",
              "c_2122": "",
              "d_1920": "",
              "d_2021": "",
              "d_2122": "",
              "e_1920": "",
              "e_2021": "",
              "e_2122": "",
              "f_1920": "",
              "f_2021": "",
              "f_2122": "",
              "g_1920": "",
              "g_2021": "",
              "g_2122": "",
              "h_1920": "",
              "h_2021": "",
              "h_2122": "",
              "i_1920": "",
              "i_2021": "",
              "i_2122": "",
              "j_1920": "",
              "j_2021": "",
              "j_2122": "",
              "k_1920": "",
              "k_2021": "",
              "k_2122": "",
              "l_1920": "",
              "l_2021": "",
              "l_2122": "",
              "m_1920": "",
              "m_2021": "",
              "m_2122": "",
              "n_1920": "",
              "n_2021": "",
              "n_2122": "",
              "o_1920": "",
              "o_2021": "",
              "o_2122": "",
              "p_1920": "",
              "p_2021": "",
              "p_2122": "",
              "q_1920": "",
              "q_2021": "",
              "q_2122": "",
              "r_1920": "",
              "r_2021": "",
              "r_2122": "",
              "s_1920": "",
              "s_2021": "",
              "s_2122": "",
              "t_1920": "",
              "t_2021": "",
              "t_2122": "",
              "u_1920": "",
              "u_2021": "",
              "u_2122": "",
              "v_1920": "",
              "v_2021": "",
              "v_2122": "",

            }
          ],

          "userStatus": false
        };

        this.productsServices = this.applicantSdata?.organizationProfile?.[0]?.productsServices || '';
        setTimeout(() => {
          this.spinner.hide();
        }, 9000);

      });
  }







  getStateByCountryName(country: any, f: any) {
    // this.spinner.show();
    let countryVal = f == 'sett' ? country.target.value : country;
    this.locationS.getStateByCountry(countryVal).subscribe({
      next: (items: any) => {

        this.statedata = items[0].states;
        // this.spinner.hide();
      },
      error: (err: any) => {
        // this.spinner.hide();


      }
    })
  }

  getStateByCountryNameBill(country: any, f: any) {
    // this.spinner.show();
    let countryVal = f == 'sett' ? country.target.value : country;
    this.locationS.getStateByCountry(countryVal).subscribe({
      next: (items: any) => {

        this.statedataBill = items[0].states;
        // this.spinner.hide();

      },
      error: (err: any) => {

        // this.spinner.hide();

      }
    })
  }


  getPromise(): Promise<any> {
    this.spinner.show();
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getData(),
        this.getStateByCountryName('India', ''),
        this.getStateByCountryNameBill('India', '')
      ])
        .then(([response1, response2, response3]) => {
          // Handle responses here if needed


          resolve({ response1, response2, response3 });
        })
        .catch((err) => {
          // Handle error here if needed
          console.error('Error:', err);
          reject(err);
        });
    });
  }

  stepForward(progress: HTMLElement) {
    this.stepArray[this.stepCounter] = false;
    this.stepCounter++;
    if (this.stepCounter > this.stepArray.length - 1) {
      this.stepCounter = this.stepArray.length - 1;
    }

    this.stepArray[this.stepCounter] = true;
    progress.scrollIntoView({ behavior: 'smooth' });
    //console.log(this.f.organizationProfile)
    return true;
  }

  stepBackward(progress: HTMLElement) {
    this.stepArray[this.stepCounter] = false;
    this.stepCounter--;
    if (this.stepCounter < 0) {
      this.stepCounter = 0;
    }
    this.stepArray[this.stepCounter] = true;
    progress.scrollIntoView({ behavior: 'smooth' });
    return true;
  }
  stepStatus(curr: number) {
    // let elem_pos=this.stepArray.indexOf(true);
    if (this.stepCounter > curr)
      return true;
    else
      return false;



  }

  stripslashes(str: any): any {
    if (typeof str !== 'string') {
      return str;
    }
    return str.replace(/\\'/g, "'").replace(/\\"/g, "'");
  }

  saveIntro() {

    this.spinner.show();
    this.appForm.value.userStatus = false;
    this.appForm.value.email = this.email;
    this.btnStatusDisabled = true;
    this.appForm.value.id = this.udata._id;
    let data = JSON.parse(this.stripslashes(JSON.stringify(this.appForm.value)))

    this.applicantS
      .updateIntroApplicantSingleEmail(data, this._id)
      .subscribe((data: any) => {
        this.toast.showSuccess(
          'Thanks!, Introduction has been saved.'
        );
        this.spinner.hide();
      });
  }


  submitIntro() {


    this.appForm.value.userStatus = true;
    this.appForm.value.email = this.email;
    this.btnStatusDisabled = false;

    if (this.appForm.valid) {

      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to submit your application for review. Once submitted, you will not be able to make any changes.',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, submit',
      }).then((result) => {
        if (result.isConfirmed) {
          this.btnStatusDisabled = true;
          this.spinner.show();
          this.applicantS
            .updateIntroApplicantSingleEmail(this.appForm.value, this._id)
            .subscribe((data: any) => {

              this.toast.showSuccess(
                'Thanks!, Introduction has been submitted.'
              );
              this.spinner.hide();
              setTimeout(() => {
                window.location.href = '/introduction';
              }, 1000);
            });
        }
        else {
          this.btnStatusDisabled = false;
        }
      });
    }
    else {

      this.submitbutton = false;
      Swal.fire({
        icon: 'error',
        title: 'Please fill all the fields!',
        text: 'All the fields in introduction form are mandatory.'
      })

    }

  }

  public onNamespaceLoaded() {


    // this.spinner.hide();
  }
  checkValid() {
  }
  convertHtmlToText(htmlString: any) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return (doc.body.textContent || '').trim();
  }
  downloadIntroductionForm() {
    let csvData: any = {};
    csvData["Organization Location Name"] = this.applicantSdata.organizationProfile[0].locationName;
    csvData["Organization Location Address"] = this.applicantSdata.organizationProfile[0].locationAddress;
    csvData["Organization Location City"] = this.applicantSdata.organizationProfile[0].locationCity;
    csvData["Organization Location Zip"] = this.applicantSdata.organizationProfile[0].locationZip;
    csvData["Organization Location State"] = this.applicantSdata.organizationProfile[0].locationState;
    csvData["Organization Scope of Award"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].scopeAward);
    csvData["Location Head Name"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].locationHeadName);
    csvData["Location Head Email"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].locationHeadEmail);
    csvData["Location Head Mobile"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].locationHeadMobile);
    csvData["Location Contact Name"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].locationContactName);
    csvData["Location Contact Email"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].locationContactEmail);
    csvData["Location Contact Mobile"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].locationContactMobile);
    csvData["Products/Services"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].productsServices);
    csvData["Customers"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].customers);
    csvData["Suppliers"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].suppliers);
    csvData["3 Years Annual Sale"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].annualSales);
    csvData["Distributor/Franchise"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].distributors);
    csvData["Competitors"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].competitors);
    csvData["People"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].people);
    csvData["Organization Structure"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].orgStructure);
    csvData["Collaborations"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].collaborations);
    csvData["Certifications/Awards"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].certifications);
    csvData["Technologies"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].technologies);
    csvData["Business Challenges"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].keyChallenges);
    csvData["IT Facilities"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].itFacilities);
    csvData["Challenging or Adverse Situation Overcame Successfully"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].overcome);
    csvData["Process Flow Chart"] = this.convertHtmlToText(this.applicantSdata.organizationProfile[0].processFlow);
    csvData["Key Food Safety - Incident 2021-2022"] = this.convertHtmlToText(this.applicantSdata.keyFoodSafety[0].incidents1920);
    csvData["Key Food Safety - Incident 2022-2023"] = this.convertHtmlToText(this.applicantSdata.keyFoodSafety[0].incidents2021);
    csvData["Key Food Safety - Incident 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.keyFoodSafety[0].incidents2122);
    csvData["Key Food Safety - Inspection 2021-2022"] = this.convertHtmlToText(this.applicantSdata.keyFoodSafety[0].inspection1920);
    csvData["Key Food Safety - Inspection 2022-2023"] = this.convertHtmlToText(this.applicantSdata.keyFoodSafety[0].inspection2021);
    csvData["Key Food Safety - Inspection 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.keyFoodSafety[0].inspection2122);
    csvData["Key Food Safety - NCRs 2021-2022"] = this.convertHtmlToText(this.applicantSdata.keyFoodSafety[0].ncr1920);
    csvData["Key Food Safety - NCRs 2022-2023"] = this.convertHtmlToText(this.applicantSdata.keyFoodSafety[0].ncr2021);
    csvData["Key Food Safety - NCRs 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.keyFoodSafety[0].ncr2122);
    csvData["Organization Performance - Revenue/Turnover 2021-2022"] = this.convertHtmlToText(this.applicantSdata.organizationPerformance[0].revenue1920);
    csvData["Organization Performance - Revenue/Turnover 2022-2023"] = this.convertHtmlToText(this.applicantSdata.organizationPerformance[0].revenue2021);
    csvData["Organization Performance - Revenue/Turnover 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.organizationPerformance[0].revenue2122);
    csvData["Organization Performance - Inventory Levels 2021-2022"] = this.convertHtmlToText(this.applicantSdata.organizationPerformance[0].inventory1920);
    csvData["Organization Performance - Inventory Levels 2022-2023"] = this.convertHtmlToText(this.applicantSdata.organizationPerformance[0].inventory2021);
    csvData["Organization Performance - Inventory Levels 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.organizationPerformance[0].inventory2122);
    csvData["Organization Performance - New Products 2021-2022"] = this.convertHtmlToText(this.applicantSdata.organizationPerformance[0].products1920);
    csvData["Organization Performance - New Products 2022-2023"] = this.convertHtmlToText(this.applicantSdata.organizationPerformance[0].products2021);
    csvData["Organization Performance - New Products 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.organizationPerformance[0].products2122);
    csvData["Organization Performance - Other Relevant 2021-2022"] = this.convertHtmlToText(this.applicantSdata.organizationPerformance[0].other1920);
    csvData["Organization Performance - Other Relevant 2022-2023"] = this.convertHtmlToText(this.applicantSdata.organizationPerformance[0].other2021);
    csvData["Organization Performance - Other Relevant 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.organizationPerformance[0].other2122);
    csvData["Trend Results - Customer Complaint 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].a_1920);
    csvData["Trend Results - Customer Complaint 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].a_2021);
    csvData["Trend Results - Customer Complaint 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].a_2122);
    csvData["Trend Results - Customer Stisfaction 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].b_1920);
    csvData["Trend Results - Customer Stisfaction 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].b_2021);
    csvData["Trend Results - Customer Stisfaction 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].b_2122);
    csvData["Trend Results - Rework (%) 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].c_1920);
    csvData["Trend Results - Rework (%) 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].c_2021);
    csvData["Trend Results - Rework (%) 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].c_2122);
    csvData["Trend Results - Scrap (%) 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].d_1920);
    csvData["Trend Results - Scrap (%) 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].d_2021);
    csvData["Trend Results - Scrap (%) 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].d_2122);
    csvData["Trend Results - Process Audit Score 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].e_1920);
    csvData["Trend Results - Process Audit Score 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].e_2021);
    csvData["Trend Results - Process Audit Score 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].e_2122);
    csvData["Trend Results - Finished Good Test Result 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].f_1920);
    csvData["Trend Results - Finished Good Test Result 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].f_2021);
    csvData["Trend Results - Finished Good Test Result 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].f_2122);
    csvData["Trend Results - Strtified Pest Trends 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].g_1920);
    csvData["Trend Results - Strtified Pest Trends 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].g_2021);
    csvData["Trend Results - Strtified Pest Trends 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].g_2122);
    csvData["Trend Results - Participantion in Imporvement Projects 2021 (%) -2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].h_1920);
    csvData["Trend Results - Participantion in Imporvement Projects 2022 (%) -2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].h_2021);
    csvData["Trend Results - Participantion in Imporvement Projects 2023 (%) -2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].h_2122);
    csvData["Trend Results - Innovation Reward Recognition Schemes 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].i_1920);
    csvData["Trend Results - Innovation Reward Recognition Schemes 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].i_2021);
    csvData["Trend Results - Innovation Reward Recognition Schemes 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].i_2122);
    csvData["Trend Results - No of Recognation Awarded for each level 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].j_1920);
    csvData["Trend Results - No of Recognation Awarded for each level 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].j_2021);
    csvData["Trend Results - No of Recognation Awarded for each level 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].j_2122);
    csvData["Trend Results - Key Equipment Wise Machine Break Down 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].k_1920);
    csvData["Trend Results - Key Equipment Wise Machine Break Down 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].k_2021);
    csvData["Trend Results - Key Equipment Wise Machine Break Down 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].k_2122);
    csvData["Trend Results - Overall Equipment Effectiveness 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].l_1920);
    csvData["Trend Results - Overall Equipment Effectiveness 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].l_2021);
    csvData["Trend Results - Overall Equipment Effectiveness 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].l_2122);
    csvData["Trend Results - Inventory Level 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].m_1920);
    csvData["Trend Results - Inventory Level 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].m_2021);
    csvData["Trend Results - Inventory Level 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].m_2122);
    csvData["Trend Results - Absenteeism Rate 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].n_1920);
    csvData["Trend Results - Absenteeism Rate 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].n_2021);
    csvData["Trend Results - Absenteeism Rate 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].n_2122);
    csvData["Trend Results - Delayed Delivery (%) 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].o_1920);
    csvData["Trend Results - Delayed Delivery (%) 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].o_2021);
    csvData["Trend Results - Delayed Delivery (%) 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].o_2122);
    csvData["Trend Results - Returned Products 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].p_1920);
    csvData["Trend Results - Returned Products 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].p_2021);
    csvData["Trend Results - Returned Products 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].p_2122);
    csvData["Trend Results - Recalls 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].q_1920);
    csvData["Trend Results - Recalls 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].q_2021);
    csvData["Trend Results - Recalls 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].q_2122);
    csvData["Trend Results - Process Parameter Non Conformances 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].r_1920);
    csvData["Trend Results - Process Parameter Non Conformances 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].r_2021);
    csvData["Trend Results - Process Parameter Non Conformances 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].r_2122);
    csvData["Trend Results - RM Test Results 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].s_1920);
    csvData["Trend Results - RM Test Results 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].s_2021);
    csvData["Trend Results - RM Test Results 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].s_2122);
    csvData["Trend Results - RM Rejects 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].t_1920);
    csvData["Trend Results - RM Rejects 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].t_2021);
    csvData["Trend Results - RM Rejects 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].t_2122);
    csvData["Trend Results - Air Pathogen Monitoring Results 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].u_1920);
    csvData["Trend Results - Air Pathogen Monitoring Results 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].u_2021);
    csvData["Trend Results - Air Pathogen Monitoring Results 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].u_2122);
    csvData["Trend Results - Others 2021-2022"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].v_1920);
    csvData["Trend Results - Others 2022-2023"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].v_2021);
    csvData["Trend Results - Others 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.trendResults[0].v_2122);
    csvData["Sector Specific Practices - Step 1 Technology/Equipment/Software"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].a_1);
    csvData["Sector Specific Practices - Step 1 Material of Construction/Program"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].a_2);
    csvData["Sector Specific Practices - Step 1 Mistake Proofing for Food Safety and Quality"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].a_3);
    csvData["Sector Specific Practices - Step 1 Instrumentation/Control/Any Others"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].a_4);
    csvData["Sector Specific Practices - Step 2 Technology/Equipment/Software"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].b_1);
    csvData["Sector Specific Practices - Step 2 Material of Construction/Program"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].b_2);
    csvData["Sector Specific Practices - Step 2 Mistake Proofing for Food Safety and Quality"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].b_3);
    csvData["Sector Specific Practices - Step 2 Instrumentation/Control/Any Others"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].b_4);
    csvData["Sector Specific Practices - Step 3 Technology/Equipment/Software"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].c_1);
    csvData["Sector Specific Practices - Step 3 Material of Construction/Program"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].c_2);
    csvData["Sector Specific Practices - Step 3 Mistake Proofing for Food Safety and Quality"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].c_3);
    csvData["Sector Specific Practices - Step 3 Instrumentation/Control/Any Others"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].c_4);
    csvData["Sector Specific Practices - Step 4 Technology/Equipment/Software"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].d_1);
    csvData["Sector Specific Practices - Step 4 Material of Construction/Program"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].d_2);
    csvData["Sector Specific Practices - Step 4 Mistake Proofing for Food Safety and Quality"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].d_3);
    csvData["Sector Specific Practices - Step 4 Instrumentation/Control/Any Others"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].d_4);
    csvData["Sector Specific Practices - Step 5 Technology/Equipment/Software"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].e_1);
    csvData["Sector Specific Practices - Step 5 Material of Construction/Program"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].e_2);
    csvData["Sector Specific Practices - Step 5 Mistake Proofing for Food Safety and Quality"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].e_3);
    csvData["Sector Specific Practices - Step 5 Instrumentation/Control/Any Others"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].e_4);
    csvData["Sector Specific Practices - Step 6 Technology/Equipment/Software"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].f_1);
    csvData["Sector Specific Practices - Step 6 Material of Construction/Program"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].f_2);
    csvData["Sector Specific Practices - Step 6 Mistake Proofing for Food Safety and Quality"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].f_3);
    csvData["Sector Specific Practices - Step 6 Instrumentation/Control/Any Others"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].f_4);
    csvData["Sector Specific Practices - Step 7 Technology/Equipment/Software"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].g_1);
    csvData["Sector Specific Practices - Step 7 Material of Construction/Program"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].g_2);
    csvData["Sector Specific Practices - Step 7 Mistake Proofing for Food Safety and Quality"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].g_3);
    csvData["Sector Specific Practices - Step 7 Instrumentation/Control/Any Others"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].g_4);
    csvData["Sector Specific Practices - Step 8 Technology/Equipment/Software"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].h_1);
    csvData["Sector Specific Practices - Step 8 Material of Construction/Program"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].h_2);
    csvData["Sector Specific Practices - Step 8 Mistake Proofing for Food Safety and Quality"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].h_3);
    csvData["Sector Specific Practices - Step 8 Instrumentation/Control/Any Others"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].h_4);
    csvData["Sector Specific Practices - Step 9 Technology/Equipment/Software"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].i_1);
    csvData["Sector Specific Practices - Step 9 Material of Construction/Program"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].i_2);
    csvData["Sector Specific Practices - Step 9 Mistake Proofing for Food Safety and Quality"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].i_3);
    csvData["Sector Specific Practices - Step 9 Instrumentation/Control/Any Others"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].i_4);
    csvData["Sector Specific Practices - Step 10 Technology/Equipment/Software"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].j_1);
    csvData["Sector Specific Practices - Step 10 Material of Construction/Program"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].j_2);
    csvData["Sector Specific Practices - Step 10 Mistake Proofing for Food Safety and Quality"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].j_3);
    csvData["Sector Specific Practices - Step 10 Instrumentation/Control/Any Others"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].j_4);
    csvData["Sector Specific Practices - Step 11 Technology/Equipment/Software"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].k_1);
    csvData["Sector Specific Practices - Step 11 Material of Construction/Program"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].k_2);
    csvData["Sector Specific Practices - Step 11 Mistake Proofing for Food Safety and Quality"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].k_3);
    csvData["Sector Specific Practices - Step 11 Instrumentation/Control/Any Others"] = this.convertHtmlToText(this.applicantSdata.sectorPractices[0].k_4);
    csvData["Other Specific Processes - Residues control"] = this.convertHtmlToText(this.applicantSdata.otherSpecific[0].primaryChemical);
    csvData["Other Specific Processes - Water Treatment"] = this.convertHtmlToText(this.applicantSdata.otherSpecific[0].waterTreatment);
    csvData["Other Specific Processes - Sensitive Ingredient "] = this.convertHtmlToText(this.applicantSdata.otherSpecific[0].ingredients);
    csvData["Other Specific Processes - Regulations "] = this.convertHtmlToText(this.applicantSdata.otherSpecific[0].regultions);
    csvData["Key Improvements - Step 1 Improvement Activity"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].a_1);
    csvData["Key Improvements - Step 1 Project Dates"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].a_2);
    csvData["Key Improvements - Step 1 Team Members involved"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].a_3);
    csvData["Key Improvements - Step 1 Benefits Obtained"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].a_4);
    csvData["Key Improvements - Step 2 Improvement Activity"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].b_1);
    csvData["Key Improvements - Step 2 Project Dates"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].b_2);
    csvData["Key Improvements - Step 2 Team Members involved"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].b_3);
    csvData["Key Improvements - Step 2 Benefits Obtained"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].b_4);
    csvData["Key Improvements - Step 3 Improvement Activity"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].c_1);
    csvData["Key Improvements - Step 3 Project Dates"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].c_2);
    csvData["Key Improvements - Step 3 Team Members involved"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].c_3);
    csvData["Key Improvements - Step 3 Benefits Obtained"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].c_4);
    csvData["Key Improvements - Step 4 Improvement Activity"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].d_1);
    csvData["Key Improvements - Step 4 Project Dates"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].d_2);
    csvData["Key Improvements - Step 4 Team Members involved"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].d_3);
    csvData["Key Improvements - Step 4 Benefits Obtained"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].d_4);
    csvData["Key Improvements - Step 5 Improvement Activity"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].e_1);
    csvData["Key Improvements - Step 5 Project Dates"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].e_2);
    csvData["Key Improvements - Step 5 Team Members involved"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].e_3);
    csvData["Key Improvements - Step 5 Benefits Obtained"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].e_4);
    csvData["Key Improvements - Step 6 Improvement Activity"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].f_1);
    csvData["Key Improvements - Step 6 Project Dates"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].f_2);
    csvData["Key Improvements - Step 6 Team Members involved"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].f_3);
    csvData["Key Improvements - Step 6 Benefits Obtained"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].f_4);
    csvData["Key Improvements - Step 7 Improvement Activity"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].g_1);
    csvData["Key Improvements - Step 7 Project Dates"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].g_2);
    csvData["Key Improvements - Step 7 Team Members involved"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].g_3);
    csvData["Key Improvements - Step 7 Benefits Obtained"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].g_4);
    csvData["Key Improvements - Step 8 Improvement Activity"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].h_1);
    csvData["Key Improvements - Step 8 Project Dates"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].h_2);
    csvData["Key Improvements - Step 8 Team Members involved"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].h_3);
    csvData["Key Improvements - Step 8 Benefits Obtained"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].h_4);
    csvData["Key Improvements - Step 9 Improvement Activity"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].i_1);
    csvData["Key Improvements - Step 9 Project Dates"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].i_2);
    csvData["Key Improvements - Step 9 Team Members involved"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].i_3);
    csvData["Key Improvements - Step 9 Benefits Obtained"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].i_4);
    csvData["Key Improvements - Step 10 Improvement Activity"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].j_1);
    csvData["Key Improvements - Step 10 Project Dates"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].j_2);
    csvData["Key Improvements - Step 10 Team Members involved"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].j_3);
    csvData["Key Improvements - Step 10 Benefits Obtained"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].j_4);
    csvData["Key Improvements - Step 11 Improvement Activity"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].k_1);
    csvData["Key Improvements - Step 11 Project Dates"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].k_2);
    csvData["Key Improvements - Step 11 Team Members involved"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].k_3);
    csvData["Key Improvements - Step 11 Benefits Obtained"] = this.convertHtmlToText(this.applicantSdata.foodImprovements[0].k_4);
    csvData["Checkpoints - Formulations"] = this.convertHtmlToText(this.applicantSdata.checkpoints[0].a_1);
    csvData["Checkpoints - Traceability"] = this.convertHtmlToText(this.applicantSdata.checkpoints[0].b_1);
    csvData["Checkpoints - Nutritional Claims"] = this.convertHtmlToText(this.applicantSdata.checkpoints[0].c_1);
    csvData["Checkpoints - Frequency of Testing"] = this.convertHtmlToText(this.applicantSdata.checkpoints[0].d_1);
    csvData["Checkpoints - Risk Assessment"] = this.convertHtmlToText(this.applicantSdata.checkpoints[0].e_1);
    csvData["Checkpoints - Limits of Lead and Heavy Metals"] = this.convertHtmlToText(this.applicantSdata.checkpoints[0].f_1);
    csvData["Checkpoints - NABL Certificate Available"] = this.convertHtmlToText(this.applicantSdata.checkpoints[0].g_1);
    csvData["Checkpoints - Quality of Water"] = this.convertHtmlToText(this.applicantSdata.checkpoints[0].h_1);
    csvData["Checkpoints - Documented"] = this.convertHtmlToText(this.applicantSdata.checkpoints[0].i_1);
    csvData["Checkpoints - External Communication"] = this.convertHtmlToText(this.applicantSdata.checkpoints[0].j_1);
    csvData["Checkpoints - Reach System"] = this.convertHtmlToText(this.applicantSdata.checkpoints[0].k_1);
    csvData["Checkpoints - Regular Testing"] = this.convertHtmlToText(this.applicantSdata.checkpoints[0].l_1);
    csvData["Checkpoints - Standards and Reports"] = this.convertHtmlToText(this.applicantSdata.checkpoints[0].m_1);
    csvData["Checkpoints - Responses"] = this.convertHtmlToText(this.applicantSdata.checkpoints[0].n_1);
    csvData["Checkpoints - Regulatory Compliance"] = this.convertHtmlToText(this.applicantSdata.checkpoints[0].o_1);
    csvData["Checkpoints - In-house Lab infrastructure"] = this.convertHtmlToText(this.applicantSdata.checkpoints[0].p_1);
    csvData["Checkpoints - Documented Regulatory Compliance Procedure"] = this.convertHtmlToText(this.applicantSdata.checkpoints[0].q_1);
    csvData["Environment - Carbon Dioxide Emission 2021-2022"] = this.convertHtmlToText(this.applicantSdata.envImpact[0].a_1);
    csvData["Environment - Carbon Dioxide Emission 2022-2023"] = this.convertHtmlToText(this.applicantSdata.envImpact[0].a_2);
    csvData["Environment - Carbon Dioxide Emission 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.envImpact[0].a_3);
    csvData["Environment - Power Consumption 2021-2022"] = this.convertHtmlToText(this.applicantSdata.envImpact[0].b_1);
    csvData["Environment - Power Consumption 2022-2023"] = this.convertHtmlToText(this.applicantSdata.envImpact[0].b_2);
    csvData["Environment - Power Consumption 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.envImpact[0].b_3);
    csvData["Environment - Water Consumption 2021-2022"] = this.convertHtmlToText(this.applicantSdata.envImpact[0].c_1);
    csvData["Environment - Water Consumption 2022-2023"] = this.convertHtmlToText(this.applicantSdata.envImpact[0].c_2);
    csvData["Environment - Water Consumption 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.envImpact[0].c_3);
    csvData["Environment - Energy Consumption 2021-2022"] = this.convertHtmlToText(this.applicantSdata.envImpact[0].d_1);
    csvData["Environment - Energy Consumption 2022-2023"] = this.convertHtmlToText(this.applicantSdata.envImpact[0].d_2);
    csvData["Environment - Energy Consumption 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.envImpact[0].d_3);
    csvData["Environment - Use of Scrap 2021-2022"] = this.convertHtmlToText(this.applicantSdata.envImpact[0].e_1);
    csvData["Environment - Use of Scrap 2022-2023"] = this.convertHtmlToText(this.applicantSdata.envImpact[0].e_2);
    csvData["Environment - Use of Scrap 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.envImpact[0].e_3);
    csvData["Environment - Use of Non-biodegradables 2021-2022"] = this.convertHtmlToText(this.applicantSdata.envImpact[0].f_1);
    csvData["Environment - Use of Non-biodegradables 2022-2023"] = this.convertHtmlToText(this.applicantSdata.envImpact[0].f_2);
    csvData["Environment - Use of Non-biodegradables 2023-2024 till date"] = this.convertHtmlToText(this.applicantSdata.envImpact[0].f_3);
    const csvRows = [];

    csvRows.push(['', ''].join(','));

    for (const [key, value] of Object.entries(csvData)) {
      csvRows.push([key, value].map((field: any) => `"${field?.toString().replace(/"/g, '""')}"`).join(','));
    }

    // Join all rows into a single string
    const csvString = csvRows.join('\r\n');

    // Create a blob and download the file
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'introduction.csv';
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    a.remove();

    // let data = [csvData];
    // const header = Object.keys(data[0]);
    // const csv = data.map((row) => header
    //   .map((fieldName) => `"${row[fieldName].replace(/"/g, '""')}"` // Quote and handle double quotes in the content
    //   )
    //   .join(','));
    // csv.unshift(header.join(','));
    // const csvArray = csv.join('\r\n');
    // const a = document.createElement('a');
    // const blob = new Blob([csvArray], { type: 'text/csv' });
    // const url = window.URL.createObjectURL(blob);
    // a.href = url;
    // a.download = 'introduction.csv';
    // a.click();
    // window.URL.revokeObjectURL(url);
    // a.remove();
  }




}
