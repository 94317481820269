export const base_url_upload = 'https://award.face-cii.in/';
export const base_url = 'https://award.face-cii.in/api/';

// export const base_url_upload = 'http://13.235.172.47:3000/';
// export const base_url = 'http://13.235.172.47:3000/api/';
// export const base_url_upload = 'http://localhost:3000/';
// export const base_url = 'http://localhost:3000/api/';
// export const base_url_upload = 'http://13.235.172.47:3000/';
// export const base_url = 'http://13.235.172.47:3000/api/';

// export const base_url = 'http://ciiface.evalue8.info/api/';
// export const base_url_upload = 'http://ciiface.evalue8.info/';
export const main_mode = false;
