import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { ApplicantService } from '../services/applicant.service';
import { QuestionService } from '../services/question.service';
import { TosterService } from '../services/toster.service';

@Component({
  selector: 'app-grand-total-list',
  templateUrl: './grand-total-list.component.html',
  styleUrls: ['./grand-total-list.component.css'],
})
export class GrandTotalListComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  udata: any;
  record: any;
  arr: any = [];
  gscore: any;
  allocation_id: any;
  setStatus: Boolean = false;
  applicant_id: any;
  constructor(
    private router: Router,
    private quest: QuestionService,
    private applicant: ApplicantService,
    private _Activatedroute: ActivatedRoute,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toast: TosterService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: true,
      searching: true,
      processing: true,
      dom: 'lBfrtip',
    };
    this.allocation_id = this._Activatedroute.snapshot.paramMap.get('id');
    this.applicant_id = this._Activatedroute.snapshot.paramMap.get('_id');
    this.udata = localStorage.getItem('userdata');
    this.udata = JSON.parse(this.udata);
    let email = this.udata.email;
    this.quest
      .getGeneratedScoreByAssessor(
        { email: this.udata.email },
        this.allocation_id
      )
      .subscribe((item: any) => {
        this.record = this.getUniqueListBy(item);
        this.dtTrigger.next();
        this.spinner.hide();
      });
  }
  getUniqueListBy(arr: any) {
    return [
      ...new Map(arr.map((item: any) => [item.section_no, item])).values(),
    ];
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  getSubsections(fscore: any) {
    this.arr = [];
    fscore.map((item: any) => {
      if (item.chkStatus == true) {
        this.arr.push(item.subsectionno);
      }
    });
    return this.arr;
  }
  sentScore(d: any) {
    this.gscore = d;
  }
  viewall(d: any) {
    this.setStatus = !d
    this.quest
      .getGeneratedScoreByAssessor(
        { email: this.udata.email },
        this.allocation_id
      )
      .subscribe((item: any) => {
        this.record = item;
        this.spinner.hide();
      });
  }
}
