import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AllocationService } from 'src/app/services/allocation.service';
import { ApplicantService } from 'src/app/services/applicant.service';
import { TosterService } from 'src/app/services/toster.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  assessordata: any;
  allval_allocationlistData: any;
  wholedata: any;
  allocation_id: any;

  constructor(
    public allocation: AllocationService,
    private toast: TosterService,
    private applicants: ApplicantService,
  ) { }
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  AllocationList: any;
  emailList1: any = [];
  emailList2: any = [];
  emailList3: any = [];
  restructuredData: any = [];
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: true,
      searching: true,
      processing: true,
      dom: 'lBfrtip',
    };
    this.allocation.getAssessorReport().subscribe((item: any) => {

      this.AllocationList = item.result;

      this.AllocationList.map((data: any) => {
        let count = 0
        this.AllocationList.map((assessor: any) => {
          if (data._id.assessor_id === assessor._id.assessor_id) { count++; }
        })
        data.count = count
        // this.allocation.getAssessorAssessmentCount(data._id.assessor_id).subscribe((data:any)=>{
        //   console.log()

        // })
        this.restructuredData[data._id.assessor_id] = data.count;

      })
      console.log(this.restructuredData, 'restructuredData')
      this.dtTrigger.next();
    });
    // this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }



}
