<app-header></app-header>
<app-sidenav></app-sidenav>

<div id="content" class="app-content" role="main">
  <div class="app-content-body ">
        <h2>Joint Applicants</h2>
        <ul class="nav nav-tabs">
          <li class="active"><a data-toggle="tab" href="#home">Varuna</a></li>
          <li><a data-toggle="tab" href="#menu1">Zeon</a></li>
          <li><a data-toggle="tab" href="#menu2">Triputi</a></li>
        </ul>

        <div class="tab-content">
          <div id="home" class="tab-pane fade in active">
            <div class="panel" style="padding: 2%;">
              <!-- <h4>Applicant Information</h4> -->
              <div class="row bb">
                <div class="col-lg-6">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Name of the Nominating (Customer) Organisation:</h5>
                      <p> Herbalife International India Pvt. Ltd.</p>
                    </div>
                    <div class="formitem">
                      <h5>Designation:</h5>
                      <p> Sr Director, Product Operations - India</p>
                    </div>
                    <div class="formitem">
                      <h5>Telephone:</h5>
                      <p> +91-9972509065</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Name of the Highest Ranking Official of the (Customer) Organisation:</h5>
                      <p>Atanu Haldar</p>
                    </div>
                    <div class="formitem">
                      <h5>Email:</h5>
                      <p>atanu@herbalife.com</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Name of the Contact Person in Nominating (Customer) Organisation</h4>
              <p>(All the communication will be
                sent to contact person)</p>
              <div class="row bb">
                <div class="col-lg-6">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Name:</h5>
                      <p>Latika Singh</p>
                    </div>
                    <div class="formitem">
                      <h5>Designation:</h5>
                      <p> Assistant Manager- QA- India
                      </p>
                    </div>
                    <div class="formitem">
                      <h5>Mobile:</h5>
                      <p> +91-6366749693</p>
                    </div>
                    <div class="formitem">
                      <h5>Email:</h5>
                      <p> latikas@herbalife.com</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Name:</h5>
                      <p> Vineet Aggarwal</p>
                    </div>
                    <div class="formitem">
                      <h5>Designation:</h5>
                      <p> Sr Manager, Quality - India</p>
                    </div>
                    <div class="formitem">
                      <h5>Mobile:</h5>
                      <p> +91-9972509065</p>
                    </div>
                    <div class="formitem">
                      <h5>Email:</h5>
                      <p>vineeta@herbalife.com</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Address</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Address of Nominating (Customer) Organisation:</h5>
                      <p> RMZ Pinnacle, No. 15, Commissariat Road, Bangalore,
                        Karnataka, India - 560025</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Product and Services Offered by Organisation</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Product and Services Offered by the Nominating (Customer) Organisation:</h5>
                      <p>Marketer of weight management, target nutrition and sports nutrition products
                        including Nutraceutical, Health Supplements,
                        Foods for Special Dietary Use for Weight Control and Management and Proprietary food
                        products</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Food Category Name and Number under which FSSAI License has been issued</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>FSSAI License Number - </h5>
                      <p>10013043000639</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Total Number of Employees in Organisation</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Total Number of Employees in the Nominating (Customer) Organisation:</h5>
                      <p>278</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Annual Sales turnover</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Annual Sales turnover of the Nominating (Customer) Organisation for previous year
                        (INR/USD)
                        2021-22:</h5>
                      <p>Turnover for financial year 2020-21 was approx. 3,000 crores. Figures for 2021-22 are
                        unaudited
                        hence cannot be shared as of now.</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Name of Organisation</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Name of Supplier/Co-Packers/Franchise/3rd Party Applicant Organisation:</h5>
                      <p>Varuna Integrated Logistics Private Limited</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Address of Supplier Unit</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Address of Supplier/Co-Packers/Franchise/3rd Party Applicant Unit:</h5>
                      <p>Varuna Integrated Logistics, Multi user facility, near Shambhu Barrier, Mehmadpur,
                        Rajpura District Patiala, Punjab 140417.</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Name of the Highest Ranking Official of the Supplier/Co-Packers/Franchise/3rd Party Applicant Unit
              </h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Name:</h5>
                      <p>Rajesh Kopparapu</p>
                    </div>
                    <div class="formitem">
                      <h5>Designation:</h5>
                      <p>Vice President – Sales</p>
                    </div>
                    <div class="formitem">
                      <h5>Mobile:</h5>
                      <p>9560790019</p>
                    </div>
                    <div class="formitem">
                      <h5>Email:</h5>
                      <p>Rajesh.Kopparapu@varuna.net</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Name of the Contact Person in Supplier/Co-Packers/Franchise/3rd Party Applicant Unit <span>(All the
                  communication will be sent to contact person)</span>
              </h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Name:</h5>
                      <p>Rajesh Kopparapu</p>
                    </div>
                    <div class="formitem">
                      <h5>Designation:</h5>
                      <p>Vice President – Sales</p>
                    </div>
                    <div class="formitem">
                      <h5>Mobile:</h5>
                      <p>9560790019</p>
                    </div>
                    <div class="formitem">
                      <h5>Email:</h5>
                      <p>Rajesh.Kopparapu@varuna.net</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Supplier operation from</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Supplier/Co-Packers/Franchise/3rd Party Applicant Unit is in operation from, <span>
                          [Date / Month/ Year]:</span></h5>
                      <p>01-Mar-1996</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Product and Services Offer</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Product and Services Offered by the Supplier/Co-Packers/Franchise/3rd Party
                        Applicant Unit:</h5>
                      <p>Transportation and Warehousing Services</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Food Category</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Food Category Name and Number under which FSSAI License has been issued:</h5>
                      <p>Trade/ Retail – Storage And No. - 10020063001651</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Total Number of Employees</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Total Number of Employees in the Supplier/Co-Packers/Franchise/3rd Party Applicant
                        Unit:</h5>
                      <p>2000</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Investment</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Investment in Plant & Machinery of the Supplier Unit (for Manufacturing):</h5>
                      <p>Not Applicable</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Annual Sales turnover 2021-22</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Annual Sales turnover of the Supplier/Co-Packers/Franchise/3rd Party Applicant unit
                        for previous
                        year (INR/USD) 2021 - 22:</h5>
                      <p>Approx 7 cr</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Criteria of Assessment</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Criteria of Assessment of the Supplier/Co-Packers/Franchise/3rd Party Applicant Unit
                        (as per Table 1
                        attached):</h5>
                      <p>Service Sector – Micro & Small Enterprises</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>For purposes of assigning categorisation to Award Applications: Micro</h4>
            </div>
          </div>
          <div id="menu1" class="tab-pane fade">
            <div class="panel" style="padding: 2%;">
              <!-- <h4>Applicant Information</h4> -->
              <div class="row bb">
                <div class="col-lg-6">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Name of Applicant Organisation:</h5>
                      <p> Herbalife International India Pvt. Ltd.</p>
                    </div>
                    <div class="formitem">
                      <h5>Designation:</h5>
                      <p> Sr Director, Product Operations - India</p>
                    </div>
                    <div class="formitem">
                      <h5>Telephone:</h5>
                      <p> +91-9972509065</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Name of the Highest Ranking Official of the Organisation:</h5>
                      <p>Atanu Haldar</p>
                    </div>
                    <div class="formitem">
                      <h5>Email:</h5>
                      <p>atanu@herbalife.com</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Name of Applicant Unit:</h5>
                      <p>Bangalore Sale Center</p>
                    </div>
                  </div>
                </div>

              </div>
              <h4>Address of Applicant Unit</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Existing Address:</h5>
                      <p> NO. 101/1, Richmond Road, Richmond Town, Bangalore - 560 025, Bangalore Urban,
                        Karnataka-560025</p>
                    </div>
                    <div class="formitem">
                      <h5>New address:</h5>
                      <p> RMZ Pinnacle, No. 15, Commissariat Road, Bangalore, Karnataka, India - 560025</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Applicant Unit is in operation from, [Date / Month/ Year]</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Existing address:</h5>
                      <p> Year – 16-Jan-2013</p>
                    </div>
                  </div>
                  <div class="formView">
                    <div class="formitem">
                      <h5>New Address:</h5>
                      <p> 6-May-2022</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Name of the Highest Ranking Official of the Applicant Unit: <span> Dinesh PS</span></h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Designation:</h5>
                      <p>Sr Director, Supply Chain - India</p>
                    </div>
                  </div>
                  <div class="formView">
                    <div class="formitem">
                      <h5>Email:</h5>
                      <p>dineshp@herbalife.com</p>
                    </div>
                  </div>
                  <div class="formView">
                    <div class="formitem">
                      <h5>Mobile:</h5>
                      <p>9880018679</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Name of the Contact Person/s in Applicant Unit: <span>(All the communication will be sent to contact
                  person/s, as mentioned here)</span></h4>
              <div class="row bb">
                <div class="col-lg-6">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Name:</h5>
                      <p>Latika Singh</p>
                    </div>
                  </div>
                  <div class="formView">
                    <div class="formitem">
                      <h5>Designation:</h5>
                      <p>Assistant Manager- QA- India</p>
                    </div>
                  </div>
                  <div class="formView">
                    <div class="formitem">
                      <h5>Mobile:</h5>
                      <p>+91-6366749693</p>
                    </div>
                  </div>
                  <div class="formView">
                    <div class="formitem">
                      <h5>Email:</h5>
                      <p>latikas@herbalife.com</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Name:</h5>
                      <p>Vineet Aggarwal</p>
                    </div>
                  </div>
                  <div class="formView">
                    <div class="formitem">
                      <h5>Designation:</h5>
                      <p>Sr Manager, Quality - India</p>
                    </div>
                  </div>
                  <div class="formView">
                    <div class="formitem">
                      <h5>Mobile:</h5>
                      <p>+91-9816093368</p>
                    </div>
                  </div>
                  <div class="formView">
                    <div class="formitem">
                      <h5>Email:</h5>
                      <p>vineeta@herbalife.com</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Product and Services Offer</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Product and Services Offered by the Applicant Unit:</h5>
                      <p>Storage, Retail & Distribution of Food Products</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Food Category Name and Number under which FSSAI License has been issued</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Trade/Retail - </h5>
                      <p>Retailer, Marketer, Distributor, Storage</p>
                    </div>
                  </div>
                  <div class="formView">
                    <div class="formitem">
                      <h5>FSSAI Lic No - </h5>
                      <p>10013043000638</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Total Number of Employees</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Total Number of Employees in the Applicant Unit:</h5>
                      <p>22</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Investment in Plant & Machinery</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Investment in Plant & Machinery of the Unit (for Manufacturing):</h5>
                      <p>Not Applicable</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Investment in Equipment</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Investment in Equipment (for Service Sector):</h5>
                      <p>Approx. 17cr</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Annual Sales turnover of the Organisation 2021-22</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Annual Sales turnover of the Organisation for previous year (INR/USD) 2021-22:</h5>
                      <p>Turnover for financial
                        year 2020-21 was approx. 3,000 crores. Figures for 2021-22 are unaudited hence
                        cannot be shared as of
                        now</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Annual Sales turnover of the Applicant 2021-22</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>Annual Sales turnover of the Organisation for previous year (INR/USD) 2021-22:</h5>
                      <p>For 2020-21 annual
                        sales turnover is Approx. 95 cr. For 2021-22, the figures are unaudited hence cannot
                        be shared as of now.</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Criteria of Assessment of the Applicant Unit</h4>
              <div class="row bb">
                <div class="col-lg-12">
                  <div class="formView">
                    <div class="formitem">
                      <h5>For purposes of assigning categorisation to Award Applications:</h5>
                      <p>Medium</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="menu2" class="tab-pane fade">

          </div>
        </div>
    </div>
</div>
