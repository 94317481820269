<app-header></app-header>
<app-sidenav></app-sidenav>

<div id="content" class="app-content" role="main">
  <div class="app-content-body ">

    <div class="wrapper-md">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <h4 class="m-n font-bold "><i class="fa fa-angle-left" aria-hidden="true"></i> Assessors View List</h4>
        </div>
        <div class="row">
          <div class="col-sm-12 header-btn">
            <div class="col-md-9" style="padding-right:0">
              <!--
              <label class="font-bold"><span
                  id="selected_checkbox_count_vehicle">0</span> Selected</label>
              <div class="btn-group g-px-12 dropdown"> <button class="btn btn-default btn-sm btn-bg dropdown-toggle"
                  data-toggle="dropdown"> <span class="dropdown-label " disabled>List View &nbsp;&nbsp;&nbsp;<i
                      class="fa fa-caret-down" aria-hidden="true"></i></span></button>
                <ul class="dropdown-menu text-left text-sm">
                  <li><a class="in-stock-open"> KANBAN </a></li>

                </ul>
              </div>

            </div>



            <div class="col-md-3">
              <div class="input-group universal-search">
                <input  type="text" class="form-control" placeholder="Enter keyword to search" name="search" [(ngModel)]="term" autocomplete="off">
                <span class="input-group-addon Search-icon"><i class="fa fa-search" aria-hidden="true"></i> </span>
              </div>
              -->
            </div>


            <div class="col-md-3">

              <div class="btn-group  dropdown create-applicant pull-right">
                <button class="btn btn-default btn-sm btn-bg-m dropdown-toggle r-2x" data-toggle="dropdown"> <span
                    class="dropdown-label g-px-5"><a routerLink="/add-assessors" routerLinkActive="active"
                      class="app-underline"><span class="txt-clr">Create Assessors</span></a></span></button>

                <input #fileInput id="file-upload" type="file" (change)="onFileSelected($event,fileInput)"
                  style="display: none;" />
                <input type="button" class="txt-clr btn btn-default btn-sm btn-bg-m dropdown-toggle r-2x"
                  value="Import users from file" onclick="document.getElementById('file-upload').click();" />

              </div>


            </div>




          </div>

        </div>


        <div class="col-md-12 ">
          <div class="table-responsive panel pd">
            <table class="panel panel-default table table-dark nowrap" datatable [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger">
              <thead>
                <tr>
                  <th scope="col">Assessors Details</th>
                  <th scope="col">Secondary Details</th>
                  <th scope="col">Education/Batch/Zone</th>
                  <th scope="col">Skill</th>
                  <!-- <th style="display:none">Name of the Assessor </th> -->
                  <th style="display:none;">Date of Birth</th>
                  <th style="display: none;">Designation</th>
                  <!-- <th style="display: none;">Email</th> -->
                  <!-- <th style="display: none;">Secondary Email</th> -->
                  <th style="display: none;">Tertiary Email</th>
                  <!-- <th style="display: none;">Mobile No</th> -->
                  <!-- <th style="display: none;">Alternate Mobile No.</th> -->
                  <!-- <th style="display: none;">Education Qualification</th> -->
                  <th style="display: none;">Are you a qualified lead auditor in FSMS</th>
                  <th style="display: none;">Domain Expertise</th>
                  <th style="display: none;">Professional Training</th>
                  <th style="display: none;">FSMS Certificate </th>
                  <th style="display: none;">Exposure</th>
                  <th style="display: none;">Experience</th>
                  <th style="display: none;">Residential Address</th>
                  <th style="display: none;">Residential Country</th>
                  <th style="display: none;">Residential State</th>
                  <th style="display: none;">Residential City</th>
                  <th style="display: none;">Zip Code</th>
                  <!-- <th style="display: none;">Organization Information</th> -->
                  <th style="display: none;">Organization Location</th>
                  <!-- <th style="display: none;">Batch</th> -->
                  <th style="display: none;">Organization Name</th>
                  <th style="display: none;">Organization Designation</th>
                  <th style="display: none;">Product / Service of the Organisation</th>
                  <th style="display: none;">Reporting to name</th>
                  <th style="display: none;">Reporting to designation</th>
                  <th style="display: none;">Reporting to email</th>

                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody class="assessors-xss">
                <ng-container *ngFor="let assessorsval of assessors">
                  <tr *ngIf="assessorsval?.isVisible===undefined || assessorsval?.isVisible==true">

                    <td>
                      <p class="app-id">{{ assessorsval.firstName}} </p>
                      <p class="abc">{{'@'}}&nbsp;<span class="abc-cls">{{ assessorsval.assessorsEmail}}</span></p>
                      <p class="icon"><i class="fa fa-phone" aria-hidden="true"></i>&nbsp;<span class="abc-cls">{{
                          assessorsval.assessorsPhone}} </span></p>
                      <p class="icon"><i class="fa fa-briefcase"></i>&nbsp;<span class="abc-cls">{{
                          assessorsval.designation}}</span></p>
                    </td>
                    <td>
                      <p>{{ assessorsval.assessorsSecondaryemail }}</p>
                      <p>{{ assessorsval.assessorsAlternateno }}</p>
                    </td>
                    <td>
                      <p class="app-id">{{ assessorsval.assessorsEducation}}</p>
                      <p class="app-id">Batch : {{ assessorsval.batch}}</p>
                      <p class="icon">Zone: {{ assessorsval.zone}}</p>
                    </td>

                    <td>Skill: {{assessorsval.score?assessorsval.score:0}}<br />Tech:
                      {{assessorsval.rating?assessorsval.rating:0}}<br />Com.:
                      {{assessorsval.rank?assessorsval.rank:'-'}}
                    </td>

                    <!-- <td style="display: none;">{{assessorsval?.firstName}}</td> -->
                    <td style="display: none;">{{assessorsval?.assessorsDate}}</td>
                    <td style="display: none;">{{assessorsval?.designation}}</td>
                    <!-- <td style="display: none;">{{assessorsval?.assessorsEmail}}</td> -->
                    <!-- <td style="display: none;">{{assessorsval?.assessorsSecondaryemail}}</td> -->
                    <td style="display: none;">{{assessorsval?.assessorsTertiaryemail}}</td>
                    <!-- <td style="display: none;">{{assessorsval?.assessorsPhone}}</td> -->
                    <!-- <td style="display: none;">{{assessorsval?.assessorsAlternateno}}</td> -->
                    <!-- <td style="display: none;">{{assessorsval?.assessorsEducation}}</td> -->
                    <td style="display: none;">{{assessorsval?.assessorsFsmsqualified}}</td>
                    <td style="display: none;">{{assessorsval?.domain}}</td>
                    <td style="display: none;">{{assessorsval?.assessorsTraining}}</td>
                    <td style="display: none;">{{assessorsval?.fsmsCertificate}}</td>
                    <td style="display: none;">{{assessorsval?.exposure}}</td>
                    <td style="display: none;">{{assessorsval?.experience}}</td>
                    <td style="display: none;">{{assessorsval?.residentialAddressline}}</td>
                    <td style="display: none;">{{assessorsval?.residentialCountry}}</td>
                    <td style="display: none;">{{assessorsval?.residentialState}}</td>
                    <td style="display: none;">{{assessorsval?.residentialCity}}</td>
                    <td style="display: none;">{{assessorsval?.residentialZipcode}}</td>



                    <!-- <td style="display: none;">{{assessorsval?.zone}}</td> -->
                    <td style="display: none;">{{assessorsval?.OrganizationLocation}}</td>
                    <!-- <td style="display: none;">{{assessorsval?.batch}}</td> -->
                    <td style="display: none;">{{assessorsval?.Organization2}}</td>
                    <td style="display: none;">{{assessorsval?.organizationDesignation2}}</td>
                    <td style="display: none;">{{assessorsval?.organizationProductservice2}}</td>
                    <td style="display: none;">{{assessorsval?.org_Fullname}}</td>
                    <td style="display: none;">{{assessorsval?.report_Designation}}</td>
                    <td style="display: none;">{{assessorsval?.org_Email}}</td>
                    <!--<td>
                    <p
                      class="btn btn-xs btn-{{assessorsval.UserData.length!=0?'success':(assessorsval.invite?'warning':'danger')}}">
                      {{assessorsval.UserData.length!=0?'Active':(assessorsval.invite?'EOI Sent':'Account Created')}}</p>
                  </td>-->
                    <td>

                      <div class="dropdown">
                        <button class="btn btn-default dropdown-toggle btn-cls" type="button" id="menu1"
                          data-toggle="dropdown"><span class="app-id"> <i class="fa fa-cog"
                              aria-hidden="true"></i>&nbsp;</span>
                          <span class="caret"></span></button>
                        <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                          <li role="presentation"><a role="menuitem" tabindex="-1"
                              href="/edit-assessors/{{assessorsval._id}}">Access Details</a></li>
                          <li class="{{assessorsval.UserData.length!=0?'disabled':''}}"><a role="menuitem" tabindex="-1"
                              (click)="reSendEoi(assessorsval._id,assessorsval.email)"> <i class="fa fa-repeat"> Resend
                                EOI
                              </i></a></li>
                          <li role="presentation"><a role="menuitem" tabindex="-1"
                              (click)="deleteAssessor(assessorsval._id)">Delete</a></li>
                          <li role="presentation" (click)="addScore(assessorsval._id)"><a type="button"
                              data-toggle="modal" data-target="#exampleModalCenter1">
                              Add Score
                            </a></li>
                          <!-- <li *ngIf="role==='super admin'"  (click)="loginAs(assessorsval)"><a type="button" 
                            >
                            Login As Assessors
                          </a></li> -->


                        </ul>
                      </div>

                    </td>
                  </tr>
                </ng-container>







              </tbody>
            </table>
          </div>
        </div>


      </div>

    </div>

  </div>


</div>

<!-- Modal -->
<div class="modal fade" id="exampleModalCenter1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="width: 40%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title font-weight-bold" id="exampleModalLongTitle">Add Score</h5>
      </div>
      <form [formGroup]="updateAssessorScoreForm" (ngSubmit)="updateScore()">
        <div class="modal-body">
          <div class="input-group mb-3"
            style="display:flex; align-items: center; margin-bottom: 1rem; justify-content: space-between;">
            <label for="Score">Skill : </label>
            <button class="DI btn btn-danger" type="button" (click)="decValue()">-</button>
            <input type="number" class="form-control" placeholder="Username" aria-label="Username"
              formControlName="score" aria-describedby="basic-addon1" style="width:70%;" [value]="this.score"
              (input)="checkNumber($event,'s1')">
            <button class="DI btn btn-success" type="button" (click)="incValue()">+</button>
          </div>
          <div class="input-group mb-3"
            style="display:flex; align-items: center; margin-bottom: 1rem; justify-content: space-between;">
            <label for="Rating">Technical Knowledge : </label>
            <button class="DI btn btn-danger" type="button" (click)="mainesValue()">-</button>
            <input type="number" class="form-control" placeholder="Username" aria-label="Username"
              formControlName="rating" aria-describedby="basic-addon1" style="width:50%;" [value]="this.rating"
              (input)="checkNumber($event,'s2')">
            <button class="DI btn btn-success" type="button" (click)="plussValue()">+</button>
          </div>
          <div class="input-group mb-3" style="display:flex; align-items: center; justify-content: space-between;">
            <div class="input-group-prepend">
              <label class="input-group-text" for="inputGroupSelect01">Communication :</label>
            </div>
            <button class="DI btn btn-danger" type="button" (click)="decValue1()">-</button>
            <input type="number" class="form-control" placeholder="Username" aria-label="Username"
              formControlName="rank" aria-describedby="basic-addon1" style="width:60%;" [value]="this.rank"
              (input)="checkNumber($event,'s3')">
            <button class="DI btn btn-success" type="button" (click)="incValue1()">+</button>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-dark" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-success">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>