<app-header></app-header>
<!-- <app-sidenav *ngIf="this.udata.role!='applicant'"></app-sidenav> -->
<app-sidenav-applicant *ngIf="this.udata.role=='applicant'"></app-sidenav-applicant>
<div id="content" role="main" class="app-content">
    <div class="app-content-body container">
        <h2>Applicant Details</h2>
        <div class="panel">
            <div class="container-fluid">
                <!-- <h4>Applicant Information</h4> -->
                <h4>Name of the Nominating (Customer) Organisation:</h4>
                <div class="row bb">
                    <div class="col-lg-12">
                        <div class="formView">
                            <div class="formitem">
                                <h5>Nestlé India Limited</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Name of the Highest Ranking Official of the (Customer) Organisation:</h4>
                <div class="row bb">
                    <div class="col-lg-12">
                        <div class="formView">
                            <div class="formitem">
                                <h5>Mr. Suresh Narayanan</h5>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Designation:</h5>
                                <p>Market Head, India</p>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Email:</h5>
                                <p>Suresh.Narayanan@IN.nestle.com</p>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Telephone:</h5>
                                <p>...</p>
                            </div>
                        </div>
                    </div>
                </div>

                <h4>Name of the Contact Person in Nominating (Customer) Organisation: <span>(All the communication will
                        be sent to contact person)</span></h4>
                <div class="row bb">
                    <div class="col-lg-6">
                        <div class="formView">
                            <div class="formitem">
                                <h5>Name:</h5>
                                <p>Nitin Rana</p>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Designation:</h5>
                                <p>Manager</p>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Mobile:</h5>
                                <p>...</p>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Telephone:</h5>
                                <p>...</p>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Email:</h5>
                                <p>nitin.kumar@in.nestle.com</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="formView">
                            <div class="formitem">
                                <h5>Name:</h5>
                                <p>...</p>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Designation:</h5>
                                <p>...</p>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Mobile:</h5>
                                <p>...</p>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Telephone:</h5>
                                <p>...</p>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Email:</h5>
                                <p>...</p>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Address of Nominating (Customer) Organisation:</h4>
                <div class="row bb">
                    <div class="col-lg-12">
                        <div class="formView">
                            <div class="formitem">
                                <h5>Head Office:</h5>
                                <p>Nestlé House, Jacaranda Marg, M Block, DLF City Phase – II, Gurugram 122 002
                                    (Haryana)</p>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Product and Services Offered by the Nominating (Customer) Organisation:</h4>
                <div class="row bb">
                    <div class="col-lg-12">
                        <div class="formView">
                            <div class="formitem">
                                <h5>Instant Noodles</h5>

                            </div>
                        </div>

                    </div>
                </div>
                <h4>Food Category Name and Number under which FSSAI License has been issued:</h4>
                <div class="row bb">
                    <div class="col-lg-12">
                        <div class="formView">
                            <div class="formitem">
                                <h5>06 - Cereals and cerealproducts, derived from cereal grains, from roots and tubers,
                                    pulses, legumes and pith or soft
                                    core of palm tree, excluding bakery wares of food category 7.0 (06.4 - Pastas and
                                    noodles and like products)</h5>

                            </div>
                        </div>
                    </div>
                </div>
                <h4>Total Number of Employees in the Nominating (Customer) Organisation:</h4>
                <div class="row bb">
                    <div class="col-lg-12">
                        <div class="formView">
                            <div class="formitem">
                                <h5>7649</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Annual Sales turnover of the Nominating (Customer) Organisation for previous year (INR/USD):
                </h4>
                <div class="row bb">
                    <div class="col-lg-12">
                        <div class="formView">
                            <div class="formitem">
                                <h5>14634 Cr</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Name of Supplier/Co-Packers/Franchise/3rd PartyApplicant Organisation:</h4>
                <div class="row bb">
                    <div class="col-lg-12">
                        <div class="formView">
                            <div class="formitem">
                                <h5>Aico Foods Limited</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Address of Supplier/Co-Packers/Franchise/3rd PartyApplicant Unit:</h4>
                <div class="row bb">
                    <div class="col-lg-12">
                        <div class="formView">
                            <div class="formitem">
                                <h5>Survey No. 661, Village Gangad, Taluka Bavla, Ahmedabad 382240</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Name of the Highest Ranking Official of the Supplier/Co-Packers/Franchise/3rd PartyApplicant Unit:
                </h4>
                <div class="row bb">
                    <div class="col-lg-6">
                        <div class="formView">
                            <div class="formitem">
                                <h5>Name:</h5>
                                <p>Divyapratapsinh B Jadeja</p>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Designation:</h5>
                                <p>Factory Manager</p>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Mobile:</h5>
                                <p>9429082006</p>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Telephone:</h5>
                                <p>+91-97000 68222 Ext. 141</p>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Email:</h5>
                                <p>dj@aicofoods.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Name of the Contact Person in Supplier/Co-Packers/Franchise/3rd PartyApplicant Unit: <span>(All the
                        communication will be sent to contact person)</span></h4>
                <div class="row bb">
                    <div class="col-lg-6">
                        <div class="formView">
                            <div class="formitem">
                                <h5>Name:</h5>
                                <p>Uday Borase</p>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Designation:</h5>
                                <p>QA Manager</p>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Mobile:</h5>
                                <p>7046651894</p>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Telephone:</h5>
                                <p>+91-97000 68222 Ext. 181</p>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>Email:</h5>
                                <p>uday.qc@aicofoods.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Supplier/Co-Packers/Franchise/3rd PartyApplicant Unit is in operation from:</h4>
                <div class="row bb">
                    <div class="col-lg-12">
                        <div class="formView">
                            <div class="formitem">
                                <h5>[Date / Month/ Year] [04/11/2020]</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Product and Services Offered by theSupplier/Co-Packers/Franchise/3rd PartyApplicant Unit:</h4>
                <div class="row bb">
                    <div class="col-lg-12">
                        <div class="formView">
                            <div class="formitem">
                                <h5>Instant Noodles Manufacturing</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Food Category Name and Number under which FSSAI License has been issued:</h4>
                <div class="row bb">
                    <div class="col-lg-12">
                        <div class="formView">
                            <div class="formitem">
                                <h5>06 - Cereals and cereal products, derived from cereal grains, from roots and tubers,
                                    pulses,legumes and pith or soft
                                    core of plam tree, excluding bakery wares of food category 7.0 (06.4 - Pastas and
                                    noodles and like products)</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Total Number of Employees in the Supplier/Co-Packers/Franchise/3rd PartyApplicant Unit:</h4>
                <div class="row bb">
                    <div class="col-lg-12">
                        <div class="formView">
                            <div class="formitem">
                                <h5>240</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Investment in Plant & Machinery of the Supplier Unit (for Manufacturing):</h4>
                <div class="row bb">
                    <div class="col-lg-12">
                        <div class="formView">
                            <div class="formitem">
                                <h5>30 Cr</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Annual Sales turnover of the Supplier/Co-Packers/Franchise/3rd PartyApplicant unit for previous year
                    (INR/USD)2021 - 22:</h4>
                <div class="row bb">
                    <div class="col-lg-12">
                        <div class="formView">
                            <div class="formitem">
                                <h5>11 Cr</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Criteria of Assessment of theSupplier/Co-Packers/Franchise/3rd PartyApplicant Unit (as per Table 1
                    attached):</h4>
                <div class="row bb">
                    <div class="col-lg-12">
                        <div class="formView">
                            <div class="formitem">
                                <h5>Medium</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Details for Raising Invoice:</h4>
                <div class="row bb">
                    <div class="col-lg-12">
                        <div class="formView">
                            <div class="formitem">
                                <h5>• GST No:_24AABCA3131E1ZH</h5>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>• Address_Survey No. 661, Village Gangad, Taluka Bavla, Ahmedabad 382240</h5>
                            </div>
                        </div>
                        <div class="formView">
                            <div class="formitem">
                                <h5>• Whether Proforma / Tax Invoice is required: _YES</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>