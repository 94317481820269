import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[type=number]'
})
export class NoScrollDirective {
  constructor(private el: ElementRef) { }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    this.el.nativeElement.blur();
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  }
}
