<app-header></app-header>
<app-sidenav-assessors></app-sidenav-assessors>
<div id="content" class="app-content" role="main">
  <div class="app-content-body container">
    <h2>Assessor Dashboard</h2>
    <!-- <div class="card">
      <img src="../../../assets/img/background-default.png" style="width:40%;" />
      <p>This section shall be available once your Application is accepted and the assessment is undertaken.</p>
    </div> -->
    <section id="dasboard-top-boxes">
      <div class="first-sec">
        <div class="top-box">
          <div class="title">
            <h6>Assessments</h6>
          </div>
          <div class="footer-number color1">
            <h5>{{ this.assessmentCount}}</h5>
          </div>
        </div>
        <div class="top-box">
          <div class="title">
            <h6>Completed</h6>
          </div>
          <div class="footer-number color2">
            <h5>0</h5>
          </div>
        </div>
        <div class="top-box">
          <div class="title">
            <h6>Pending</h6>
          </div>
          <div class="footer-number color3">
            <h5>{{ this.assessmentCount}}</h5>
          </div>
        </div>
      </div>
    </section>
    <section id="assisment-table">
      <div class="applicants-title">
        <h6>ASSESSMENTS</h6>
      </div>
      <div class="applicants-data">
        <table class="panel panel-default table table-dark nowrap" datatable [dtOptions]="dtOptions"
          [dtTrigger]="dtTrigger">
          <thead>
            <tr>
              <th>APPLICANT DETAIL</th>
              <th>ASSESSMENT SCHEDULE</th>
              <th>Created On</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let allval of AllocationList">
              <td>
                <span class="app-id">Name: {{ allval?.applicantData[0]?.firstName}}</span><br />
                <span>Email: {{ allval?.applicantData[0]?.email}}</span><br />
                <span>{{ allval?.applicantData[0]?.criteria}}, {{ allval?.applicantData[0]?.sector}}</span>
              </td>
              <td>
                From:<b> {{ allval.period_from|date:'dd-MM-yyyy' }} </b> - To:<b> {{ allval.period_to|date:'dd-MM-yyyy'
                  }}</b>
              </td>
              <td> {{ allval.created_at|date:'dd-MM-yyyy' }}</td>
              <td><span class="{{allval.allocationliststatus}}">{{ allval.allocationliststatus | titlecase }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</div>